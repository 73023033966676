@import 'assets/css/variables.scss';

.wExchange {
  .exchange-top {
    margin-bottom: 24px;
  }
  .exchange-body {
    max-width: 1000px;
    margin: 0 auto;
    @include media-down(sm) {
      padding: 16px;
    }
    @include media-down(xs) {
      padding: 6px;
    }
  }
  .exchange-content {
  }
}
