@import 'assets/css/variables.scss';

.item-package-type-wrapper {
  width: 100%;
  background: #eefbff;
  border-radius: 12px;
  margin-bottom: 16px;
  border: 1px solid $secondary;
  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    border-bottom: 1px solid #08a7b7;
    &:last-child {
      border: none;
    }
    @media (min-width: 768px) {
      padding: 0 14px;
    }
    p {
      margin-bottom: 16px;
      color: $text-main;
      span {
        color: $text-main;
      }
      &:first-child {
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 700;
      }
      &:last-child {
        margin-bottom: 0;
        font-size: 14px;
      }

      &[data-status='COMPLETED'] {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        background: #00930f;
        border-radius: 4px;
        color: #fff;
        display: flex;
        padding: 0 8px;
        min-width: 80px;
        min-height: 24px;
        align-items: center;
        justify-content: center;
      }
      &[data-status='PENDING'] {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        background: #fdc22a;
        border-radius: 4px;
        min-width: 80px;
        min-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        font-size: 14px;
      }
    }

    &:first-child {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: $text-main;

        &:first-child {
          font-weight: 600;
          font-size: 14px;
          color: $text-main;
        }
        &:last-child {
          font-weight: 700;
          color: #00930f;
          text-transform: uppercase;
        }
      }
      a {
        font-size: 14px;
      }
    }
  }
}
