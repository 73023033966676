@import 'assets/css/variables.scss';

.button-primary {
  width: 100%;
  padding: 3px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;

  border-radius: 12px;
  border: none;
  outline: unset;
  cursor: pointer;
  position: relative;
  align-items: center;

  &[data-size='small'] {
    font-size: 10px;
    line-height: 0;
    min-height: 20px;

    @include media-up(sm) {
      font-size: 12px;
      min-height: 26px;
    }
  }

  &[data-size='standard'] {
    font-size: 14px;
    line-height: 0;
    min-height: 40px;

    @include media-up(sm) {
      font-size: 16px;
      min-height: 46px;
    }
  }

  &[data-size='larger'] {
    font-size: 16px;
    line-height: 0;
    min-height: 56px;

    @include media-up(sm) {
      font-size: 18px;
      min-height: 66px;
    }
  }

  color: #000;
  border: 1px solid #069cc9;
  background-color: transparent;
  &.active {
    color: #fff;
    background: #069cc9;
  }

  &[disabled],
  &[data-loading='true'] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.danger {
    color: #fa2256;
    background: transparent;
    border-color: #fa2256;
  }

  .ant-spin {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
