@import 'assets/css/variables.scss';

$pText: $text-main;
$pTextActive: #005f9e;
$pBackground: #eefbff;
$pBorder: #069cc9;

.wCustomTable {
  width: 100%;
  padding: 24px;
  border: 1px solid $pBorder;
  background: $pBackground;
  border-radius: 12px;
  .ant-table {
    background: transparent;
    .ant-table-header {
      border-bottom: 1px solid #069cc9;
    }
    .ant-table-body {
      margin-top: 16px;
      overflow: hidden;
      @include media-up(lg) {
        overflow: auto !important;
      }
    }
    .ant-table-thead {
      height: 0;
      tr > th {
        color: $primary;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        padding: 0 8px 8px;
        background: transparent;
        border: unset;
        border-bottom: 1px solid rgba(47, 180, 255, 0.1);
        @include media-up(lg) {
          font-size: 20px;
        }
        &:first-child {
          padding-left: 0;
          text-align: left;
        }
      }
    }
    .ant-table-tbody {
      tr {
        &:hover {
          td {
            background: transparent !important;
          }
        }
        td {
          color: $pText;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          padding: 16px 8px;
          text-align: left;
          background: transparent;
          border: unset;
          &:first-child {
            padding-left: 0;
            text-align: left;
          }
        }
      }
    }
    .ant-empty-description {
      color: $pText;
    }
  }
  ul.ant-pagination {
    margin-bottom: 0;
    li {
      border-radius: 6px;
      background: transparent;
      border: 1px solid #005f9e;
      &:hover {
        background: #eefbff;
        border-radius: 4px;
      }
      button,
      a {
        color: $text-main;
      }
      &.ant-pagination-prev,
      &.ant-pagination-next {
        position: relative;
        &::after {
          content: ' ';
          width: 20px;
          height: 20px;
          object-fit: contain;
          left: 50%;
          bottom: 50%;
          transform: translate(-50%, -50%);
        }
        button {
          background: transparent;
          border: none;
        }
      }
      &.ant-pagination-item-active {
        background: #eefbff;
        border-radius: 4px;
        a {
          color: $primary;
          font-weight: 700;
        }
      }
      &.ant-pagination-options {
        &:hover {
          background: transparent;
        }
        .ant-select-selector {
          color: #000000;
          border-radius: 6px;
          background: transparent;
          border: 1px solid #005f9e;
        }
        .ant-select-arrow {
          color: #fff;
        }
      }
    }
  }
}
