@import 'assets/css/variables.scss';

.w-header-desktop {
  max-width: 100%;
  margin: 0 auto;
  align-items: center;
  background: #fcfcfd;
  border-bottom: 1px solid #069cc9;
  .header-body {
    height: 100%;
    padding: 0 15px;
    border-radius: 10px;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    @include media-up(md) {
      display: flex;
      z-index: 2;
      max-width: 1290px;
      margin: 0 auto;
    }
    .header-left {
      .header-logo {
        width: 120px;
        object-fit: contain;
        @include media-up(md) {
          width: 190px;
        }
      }
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        margin-bottom: 0;
        @include media-up(md) {
          font-size: 16px;
        }
      }
    }
    .header-right {
      height: 60px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      .avatar-image {
        width: fit-content;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        & > div {
          width: 40px;
          height: 40px;
          padding: 2px;
          border-radius: 100%;
          background: linear-gradient(to left, rgba(0, 117, 254, 1) 0%, rgba(0, 194, 254, 1) 100%);
          overflow: hidden;
        }
        span:first-child {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 100%;
          background: #000;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          margin-right: 20px;
        }
        span:last-child {
          color: #fcfcfd;
          font-weight: 600;
          font-size: 16px;
          line-height: 130%;
          white-space: nowrap;
        }
      }
      .select-chain {
        height: 100%;
        width: 120px;
        margin-right: 22px;
        .ant-select-selector {
          background: transparent;
          border-radius: 10px;
        }
        .ant-select-selection-item {
          color: #fff;
        }
        .ant-select-arrow {
          color: #fff;
          z-index: 6;
        }
      }
      .menu-collapse {
        width: 30px;
        height: 30px;
        margin-left: 6px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .header-right-actions {
        display: flex;
        align-items: center;
        .actions {
          width: 104px;
        }
      }
    }
  }
}

.menu-content-mobile {
  position: fixed;
  // top: 80px;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 0px;
  background: $secondary;
  z-index: 10;
  transition: ease-in-out 0.4s;
  opacity: 0;
  overflow: auto;

  visibility: hidden;
  &[data-visible='true'] {
    visibility: unset;
    height: 100%;
    opacity: 1;
  }
  .collapse-icon {
    display: flex;
    align-items: center;
    transform: scale(-1);
    margin-top: 30px;
    margin-right: 20px;
    input#menu {
      display: none;
    }

    .icon {
      width: 34px;
      height: auto;
      padding: 0;
      display: block;
      cursor: pointer;
    }

    .icon .menu,
    .icon .menu::before,
    .icon .menu::after {
      background: #fff;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
    }
    .icon .menu::before {
      width: 28px;
    }
    .icon .menu {
      width: 24px;
    }
    .icon .menu::after {
      width: 34px;
    }

    .icon:hover .menu,
    .icon:hover .menu::before,
    .icon:hover .menu::after {
      background: #fff;
    }

    .icon .menu::before {
      top: -8px;
    }

    .icon .menu::after {
      top: 8px;
    }

    #menu:checked + .icon .menu {
      background: transparent;
    }

    #menu:checked + .icon .menu::before {
      width: 28px;
      transform: rotate(45deg);
    }

    #menu:checked + .icon .menu::after {
      width: 28px;
      transform: rotate(-45deg);
    }

    #menu:checked + .icon .menu::before,
    #menu:checked + .icon .menu::after {
      top: 0;
      transition: top ease 0.3s, transform ease 0.3s 0.3s;
    }
  }
  .menu-body {
    min-height: 500px;
  }

  /*
  * Menu mobile Container 
  */
  ul.menu-link {
    list-style: none;
    margin: 16px 0 0;
    flex-flow: column nowrap;

    li {
      white-space: nowrap;
      padding: 16px 30px;
      a {
        color: rgba(93, 101, 136, 1);

        font-weight: 600;
        font-size: 18px;
        line-height: 160%;

        width: 100%;
        display: block;
        align-items: center;
        text-align: center;
      }
    }
    li.active {
      position: relative;
      a {
        color: #ffffff;
      }
    }
  }

  .menu-actions-mobile {
    width: 100%;
    padding: 30px 24px;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    a {
      width: 100%;
      max-width: 127px;
      display: block;
      background: unset;
    }
    & > a:first-child {
      margin-right: 10px;
    }
    & > a:last-child {
      margin-left: 10px;
    }
  }
}
