@import 'assets/css/variables.scss';

.table-transaction-history-container {
  .recent-transaction-title {
    color: #007ca2;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    margin-bottom: 24px;

    @include media-down(md) {
      font-size: 16px;
    }
  }
  .recent-transaction-sort {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    @media (min-width: 768px) {
      margin-bottom: 24px;
    }

    .sort-filter-action-left {
      display: flex;
      width: 133px;
      align-items: center;
      justify-content: space-between;
      background: #eefbff;
      border: 1px solid #069cc9;
      border-radius: 8px;

      @media (min-width: 991px) {
        width: 220px;
      }

      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #292929;
        margin-bottom: 0;
      }
      img {
        width: 16px;
        cursor: pointer;
        object-fit: contain;

        @media (min-width: 991px) {
          width: 24px;
        }
      }
      .package-wrap-select {
        height: 25px;

        @media (min-width: 991px) {
          height: 45px;
        }

        .ant-select {
          .ant-select-selector {
            padding-right: 32px;
            height: 25px;

            @media (min-width: 991px) {
              height: 45px;
            }
            .select-label {
              font-size: 10px;
              @media (min-width: 991px) {
                font-size: 16px;
              }
            }
            .ant-select-selection-placeholder {
              display: flex;
              align-items: center;
              font-size: 12px;
              line-height: 140%;

              @media (min-width: 991px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .sort-filter-action-right {
      width: 306px;
      margin-left: 16px;
      .ant-space-item {
        svg {
          width: 16px;
          @media (min-width: 991px) {
            width: 20px;
          }
        }
      }
      .ant-picker {
        height: unset;
        @media (min-width: 991px) {
          height: 45px;
        }
        input {
          font-size: 10px !important;
          @media (min-width: 768px) {
            font-size: 12px !important;
          }
          @media (min-width: 991px) {
            font-size: 16px !important;
          }
        }
        .ant-picker-active-bar {
          width: 70px !important;
          @media (min-width: 991px) {
            width: 85px !important;
          }
        }
      }
    }
  }
  .ant-table {
    width: 100%;
  }

  .ant-table-wrapper .ant-table {
    background: #eefbff;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    padding: 16px 32px;
    tr {
      td {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        text-align: center;
        color: #000000;
        > p {
          color: #000000;
          font-weight: 400;
        }
        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 150%;
        }
      }
    }

    thead tr > th {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #007ca2;
      background: transparent;
      > div {
        color: #007ca2;
        font-weight: 700;
      }
      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 26px;
      }

      &:not(:first-child) {
        &:not(:nth-last-child(2)) {
          text-align: center;
        }
      }
    }
    tbody tr {
      td {
        border: none;
        p {
          text-align: center;
          &.recent-transaction-table-action {
            background: rgba(0, 124, 161, 0.26);
            border-radius: 8px;
            width: fit-content;
            padding: 4px 12px;
            margin: 0 auto;
            min-width: 145px;
            width: 100%;
          }
        }
      }
    }
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: rgba(#fff, 0.3);
  }

  #scrollableDiv {
    .infinite-scroll-component__outerdiv {
      .ant-spin-container {
        .recent-transaction-dashboard {
          background: #eefbff;
          border-radius: 12px;
          padding: 16px 24px;
        }
      }
    }
  }
}
