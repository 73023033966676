.warp-page-account-activity {
  padding: 0 10px;
  overflow: auto;
  margin: auto;
  @media (min-width: 991px) {
    height: unset;
    margin-top: 0;
  }
}
.activity-btn-logout {
  margin-top: 20px;
  button {
    display: flex;
    justify-content: center;
    margin: 14px auto 24px;
  }
}
