@import 'assets/css/variables.scss';
.box-amount {
  margin-bottom: 24px;

  .w-input-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: 8px;
    .input-label {
      color: $primary;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      @media (min-width: 991px) {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
      }
    }

    .balance-label {
      color: $primary;
      font-weight: 400;
      font-size: 14px;
      @media (min-width: 991px) {
        line-height: 24px;
        font-size: 16px;
      }

      &:first-child {
        color: #ef466f;
      }
      span {
        color: $text-main;
        font-weight: 700;
        line-height: 27px;
      }
    }
  }

  .box-input-amount {
    position: relative;
    margin-bottom: 8px;

    input {
      width: 100%;
      height: 40px;
      outline: none;
      color: $text-main;
      font-size: 12px;
      font-weight: 700;
      line-height: 24px;
      border-radius: 16px;
      box-sizing: border-box;
      background: transparent;
      border: 1px solid $primary;
      padding-right: 60px;
      padding: 10px 16px;
      @media (min-width: 768px) {
        font-size: 16px;
        height: 50px;
      }
      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    button {
      border: none;
      outline: none;
      background: transparent;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      color: $text-main;
      padding: 8px 16px;
      cursor: pointer;
    }
  }
  .wrapper-availabel-fee {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .availabel-bottom {
      p {
        color: #fff;
        font-weight: 400;
        font-size: 11px;
        @media (min-width: 991px) {
          line-height: 24px;
          font-size: 16px;
        }
        span {
          color: #fff;
          font-size: 11px;
          font-weight: 600;
          line-height: 27px;
          margin-left: 8px;
          @media (min-width: 991px) {
            margin-left: 12px;
            font-size: 16px;
          }
        }
      }
    }
    .amount-fee {
      display: flex;
      flex-direction: row;
      p {
        color: $primary;
        font-weight: 400;
        font-size: 11px;
        line-height: 24px;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        span {
          color: $text-main;
          font-weight: 700;
          text-align: right;
        }

        @media (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }

  @media (min-width: 991px) {
    margin-bottom: 32px;

    .amount-title {
      line-height: 27px;
    }

    .amount-avaiable {
      margin-bottom: 8px;
    }
    .box-input-amount {
      margin-bottom: 16px;
    }
  }
}
