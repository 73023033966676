.Active-item-recent-transaction {
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #a7ddd2;
  div {
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
    p {
      color: #292929;
      font-size: 14px;
    }
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div {
    margin-bottom: 16px;
    p {
      &:first-child {
        font-weight: 600;
      }
      margin-bottom: 0;
      &:last-child {
        margin-bottom: 0;
      }

      &[data-status='COMPLETED'] {
        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 150%;
          text-align: right;
          color: #00f4a3;
          border-radius: 3px;
          min-width: 80px;
          min-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .ts-log-out {
      color: #bd1207;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
    }
    &:first-child {
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
        &:first-child {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 0;
          color: #292929;
          opacity: 0.4;
        }
      }
      a {
        font-size: 16px;
      }
    }

    &:last-child {
      a {
        display: block;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #bd1207;
        margin-bottom: 16px;
      }

      p {
        font-size: 14px;
        line-height: 150%;
        color: #292929;
      }
    }
  }
}
