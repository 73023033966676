@import 'assets/css/variables.scss';
.button-back-sign-in {
  position: absolute;
  top: -40%;
  left: 24px;
  width: 14px;
  height: 11px;
  z-index: 2;
  cursor: pointer;
  @include media-up(md) {
    display: none;
  }
}
.authen-code {
  max-width: 551px;
  padding: 64px 14px;
  @include media-up(md) {
    padding: 48px;
  }
  .authen-title {
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 24px;
    line-height: 36px;
    text-align: center;
    text-transform: capitalize;

    @include media-up(md) {
      margin-bottom: 32px;
      font-size: 32px;
    }
  }
  .authen-des {
    color: #e5e6ed;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  .verify-resend {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;

    > div,
    > a {
      color: #82c0df;

      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      @include media-up(sm) {
        font-size: 16px;
      }
    }
    a:hover {
      opacity: 0.7;
    }
    a:active {
      opacity: 1;
    }
  }
  .authen-error {
    color: $warning;
    font-size: 12px;
    text-align: center;
    padding: 20px 10px;
    @include media-up(sm) {
      font-size: 14px;
    }
  }
  .authen-action {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    button {
      max-width: 100%;
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    a {
      color: $link;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .authen-exclamation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    padding: 20px;
    margin-top: 40px;

    background: #141518;
    border-radius: 10px;

    svg {
      margin-right: 10px;
    }
    p {
      color: #a5adcf;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
