@import 'assets/css/variables.scss';
.history-table {
  .list-history-login {
    background: #eefbff;
    border-radius: 12px;
    padding: 15px;
  }
  .pagination-wrapper-customer {
    margin-top: 16px;
  }
}
