@import 'assets/css/variables.scss';

.wallet-list-container {
  min-height: 300px;
  .navigation-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    .navigation-left {
      width: 100%;
      margin-right: 0px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      row-gap: 16px;

      @media (min-width: 576px) {
        margin-right: 20px;
        align-items: center;
        flex-direction: row;
        align-items: center;
      }

      @include media-up(md) {
        width: 100%;
        flex: 1;
      }
      h2 {
        white-space: nowrap;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: $primary;
        margin-bottom: 0;

        @include media-up(md) {
          font-size: 32px;
        }
      }
      .right-switch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (min-width: 576px) {
          width: auto;
        }

        @include media-up(md) {
          margin-right: 8px;
        }
        p {
          font-weight: 500;
          line-height: 16px;
          color: $primary;
          margin-right: 10px;
          font-size: 12px;
          @include media-up(md) {
            font-size: 16px;
          }
        }
        .ant-switch-handle {
          left: 4px;
          &::before {
            background: #d9d9d9;
            width: 15px;
            height: 15px;
          }
        }
        .ant-switch {
          background: transparent;
          max-width: 37px;
          width: 100%;
          border: 1px solid $primary;
          border-radius: 100px;
        }
        .ant-switch-checked {
          background: transparent;
          border: 1px solid $primary;
          transition: all 0.5s ease;
          .ant-switch-handle {
            left: calc(100% - 18px - 1px);
            &::before {
              background: $primary;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
    .navigation-right {
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: center;
      width: 100%;
      @include media-up(lg) {
        width: 260px;
        margin-top: 0px;
        justify-content: space-between;
      }
      .navigation-right-search {
        display: flex;
        flex-direction: row-reverse;
        height: 45px;
        background: $bg-input;
        border: 1px solid $secondary;
        border-radius: 8px;
        outline: none;

        img {
          width: 18px;
        }
        input {
          margin-left: 8px;
          &::placeholder {
            color: rgba(41, 41, 41, 0.4);
          }
        }
      }
    }
  }
  .create-wallet {
    width: 100%;
    margin-bottom: 4px;
    display: flex;
    justify-content: flex-end;
    button {
      width: fit-content;
    }
  }
  .ant-table-tbody tr {
    &:last-child {
      td {
        padding-bottom: 0;
      }
    }
  }
}
