@import 'assets/css/variables.scss';

.page-security-content-heading {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #007ca2;
  // padding: 0 15px;
  margin-bottom: 16px;

  svg {
    width: 16px;
    margin-right: 16px;
  }
}

.warp-page-security {
  position: relative;
  z-index: 1;

  @media (max-width: 992px) {
    &.wBody {
      padding: 0 15px;
      background: transparent;
    }
  }

  .page-security-content {
    @media (max-width: 992px) {
      padding: 8px;
      background: #eefbff;
    }

    .page-security-content-body {
      .page-security-content-heading {
        color: $text-main;
        font-weight: 700;
        font-size: 32px;
        line-height: 100%;
        text-transform: capitalize;
        margin-bottom: 32px;
      }
      @media (min-width: 768px) {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 12px;
      }
      .security-item {
        margin-bottom: 24px;
        border-bottom: 0.5px solid #007ca2;
        padding-bottom: 16px;
        &:last-child {
          border-bottom: unset;
        }
        @media (min-width: 768px) {
          margin-bottom: 32px;
          border-bottom: 1px solid #fff;
          padding-bottom: 24px;
          border-bottom: unset;
          padding-bottom: unset;
        }
        &:last-child {
          margin-bottom: 0;
        }
        p {
          color: rgba($text-main, 0.7);
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;

          &:first-child {
            color: $text-main;
            font-weight: 600;
            font-size: 16px;
            line-height: 27px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (min-width: 991px) {
              font-size: 20px;
            }
          }
        }
        button {
          width: 90px;
          height: 30px;
          min-height: 30px;

          @media (min-width: 991px) {
            width: 118px;
            height: 45px;
            min-height: 45px;
          }
        }
      }
    }

    @media (min-width: 991px) {
      .page-security-content-heading {
        margin-bottom: 32px;

        .box-kyc {
          padding-bottom: 20px;
          margin-bottom: 20px;

          p {
            font-size: 16px;

            &:first-child {
              font-size: 24px;
            }
          }
        }
        .info-kyc {
          .info-item {
            span {
              font-size: 14px;
            }
          }
        }
      }

      .page-security-content-body {
        .page-security-content-heading {
          font-size: 48px;
        }
        .security-item {
          p {
            font-size: 16px;
            line-height: 27px;

            &:first-child {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
