@import 'assets/css/variables.scss';

.wTxStatus {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  padding: 4px 16px;
  border-radius: 4px;
  @include media-down(md) {
    font-size: 13px;
  }
  &[data-status='CREATED'] {
    color: white;
    background: #fdc22a;
  }
  &[data-status='COMPLETED'] {
    color: white;
    background: #00930f;
  }
  &[data-status='PENDING'] {
    color: #fff;
    background: #e7dbb0;
  }
  &[data-status='PROCESSING'] {
    color: white;
    background: #fdc22a;
  }
  &[data-status='ACCEPTED'] {
    color: white;
    background: #fdc22a;
  }
  &[data-status='FAILED'] {
    color: white;
    background: #7c7c7c;
  }
  &[data-status='CANCEL'] {
    color: white;
    background: #7c7c7c;
  }
}
