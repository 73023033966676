@import 'assets/css/variables.scss';

.card-balance {
  padding: 40px 15px 24px;
  margin-bottom: 50px;
  padding-bottom: 55px;

  display: flex;
  flex-direction: column;
  row-gap: 22px;

  position: relative;
  background: #eefbff;
  box-shadow: 0px 2px 4px rgba(34, 148, 164, 0.35);
  border: 1px solid #08a7b7;
  border-radius: 12px;

  @include media-down(md) {
    padding-bottom: 32px;
    margin-bottom: 32px;
    row-gap: 0px;
    background: #eefbff;
    border-radius: 20px;
    border: 1px solid $primary;
  }

  .decoration-left-mobile {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 50px;
    @include media-up(md) {
      width: 80px;
    }
  }
  .decoration-right-mobile {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    @include media-up(md) {
      width: 80px;
    }
  }

  .card-header {
    display: flex;
    justify-content: center;

    .balance-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      color: $primary;

      @include media-up(md) {
        font-size: 24px;
        line-height: 21px;
      }
    }
    .card-percent {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: $primary;
      svg {
        height: 16px;
        margin-right: 5px;
      }

      &[data-up='true'] {
        color: $success;
        fill: $success;
      }
      &[data-up='false'] {
        color: $warning;
        fill: $warning;
      }
    }
  }
  .card-content {
    display: flex;
    text-align: center;
    justify-content: center;
    z-index: 10;

    span {
      color: $primary;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      @include media-up(md) {
        font-size: 42px;
      }
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        display: inline-block;
        width: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .card-button {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    row-gap: 12px;
    @include media-down(md) {
      max-width: 310px;
      margin-top: 20px;
    }
    a {
      display: inline-block;

      button {
        padding: 0;
        width: 80px;
        max-width: 100%;

        @media (min-width: 380px) {
          width: 94px;
        }

        @media (min-width: 991px) {
          width: 120px;
        }
      }
    }
  }
}
