@import 'assets/css/variables.scss';
.card-exchange {
  .exchange-swap-icon {
    display: flex;
    justify-content: center;

    .icon-down {
      width: 42px;
      height: 42px;
      background-color: #0ca8b7;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .exchange-swap-action {
    display: flex;
    justify-content: center;
    & > button {
      color: #ffffff;
      font-weight: 700;
      font-size: 16px;

      height: 46px;
      max-width: 200px;
      margin: 12px 0 40px;

      background: #069cc9;
      border-radius: 12px;

      @include media-down(sm) {
        margin: 12px 0 10px;
      }
    }
  }
}
