@import 'assets/css/variables.scss';

.button-secondary {
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  background: transparent;
  div {
    span {
      background: linear-gradient(90deg, #8492ff 0%, #ace1ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    padding: 2px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    background: linear-gradient(90deg, #8492ff 0%, #ace1ff 100%);
  }
  &:hover {
    background: linear-gradient(90deg, #8492ff 0%, #ace1ff 100%);
    color: #fff;
    border-radius: 8px;
    color: #020068;
    border: none;
    opacity: 1;
    span {
      color: #020068;
      background: transparent;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
      background-clip: unset;
      text-fill-color: unset;
    }
  }
  &[data-size='small'] {
    font-size: 10px;
    line-height: 20px;
    height: 20px;
    @include media-up(sm) {
      font-size: 12px;
      height: 26px;
    }
  }
  &[data-size='standard'] {
    font-size: 14px;
    line-height: 22px;
    height: 42px;
    @include media-up(sm) {
      font-size: 16px;
      height: 50px;
      max-width: 150px;
    }
  }
  &[data-size='larger'] {
    font-size: 16px;
    line-height: 24px;
    height: 56px;
    @include media-up(sm) {
      font-size: 18px;
      height: 66px;
    }
  }
  &:active {
    opacity: 1;
  }

  &[disabled],
  &[data-loading='true'] {
    cursor: not-allowed;
  }
  &[data-hoverable='false'] {
    opacity: 1;
    cursor: default;
  }

  .ant-spin {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
