.modal-soon {
  .ant-modal-body {
    padding: 60px 30px;
    h2 {
      color: #fff;
      font-size: 44px;
      font-weight: bold;
      text-align: center;
    }
    p {
      color: #fff;
      font-size: 20px;
      text-align: center;
    }
  }
}
