@import './variables.scss';
@import './font-face.scss';
@import './animation.scss';
@import './resets.scss';
@import './components.scss';
@import './global-class.scss';
@import './embed-responsive.scss';

* {
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

*::after,
*::before {
  box-sizing: border-box;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #01acc3;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 10px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  color: #ffffff;
  fill: #ffffff;
  // min-height: 550px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#root {
  width: 100%;
}

.container {
  width: 100%;
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.grecaptcha-badge {
  visibility: hidden !important;
}
