.wOption-sign {
  padding: 1px;
  background: linear-gradient(179.65deg, #08a7b7 0.79%, #3fbdbe 100%);
  border-radius: 12px;

  button {
    color: #292929;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    width: 100%;
    height: 80px;
    background: #eefbff;
    border: unset;
    border-radius: 10px;

    &:hover,
    &:focus,
    &:active,
    &:valid {
      color: #292929;
      background: #eefbff !important;
    }
  }

  &:hover {
    opacity: 0.8;

    button {
      color: #292929;
      background: #eefbff;
    }
  }
}

@media (max-width: 575px) {
  .wOption-sign {
    button {
      font-size: 16px;
      height: 50px;
    }
  }
}
