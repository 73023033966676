@import 'assets/css/variables.scss';
.box-show-withdraw-address-text {
  align-items: center;
  > p {
    color: $primary;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 8px;
    white-space: break-spaces;
    word-break: break-word;
    @media (min-width: 768px) {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }
  .wrapper-wd-input-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;

    .input-text-item {
      margin-right: 8px;

      @media (min-width: 991px) {
        margin-right: 16px;
      }
    }

    .address-action {
      display: flex;
      position: relative;
      margin-left: 8px;
      @media (min-width: 768px) {
        margin-left: 16px;
      }
      img {
        width: 45px;
        cursor: pointer;
        &:first-child {
          margin-right: 6px;
          @media (min-width: 991px) {
            margin-right: 16px;
          }
        }

        @media (min-width: 768px) {
          width: 50px;
        }
      }
      .tooltip {
        position: absolute;
        content: '';
        background: rgba(0, 0, 0, 0.205);
        width: fit-content;
        height: fit-content;
        padding: 4px 8px;
        top: 50%;
        left: 50%;
        border-radius: 20px;
        transform: translate(-50%, 90%);
      }
      .tooltip-qr {
        position: absolute;
        content: '';
        background: rgb(0, 0, 0);
        width: fit-content;
        height: fit-content;
        padding: 10px;
        top: 50%;
        left: 50%;
        border-radius: 20px;
        transform: translate(-20%, 55%);
      }
    }
  }
}
