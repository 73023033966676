@import 'assets/css/variables.scss';
.total-balance {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > div {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;

    height: 45px;
    // max-width: 150px;
    padding: 4px 14px;

    background: #f3f3f3;
    border: 0.5px solid #0aadad;
    border-radius: 8px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    @include media-down(md) {
      font-size: 12px;
      max-width: 180px;
      height: 30px;
    }

    .left-balance {
      color: #0aadad;
      font-weight: 700;
      line-height: 140%;
      margin-right: 15px;
      white-space: nowrap;
    }
  }
  svg {
    width: 14px;
    path {
      &:first-child {
        fill: #0aadad;
      }
      &:nth-child(2) {
        fill: #0aadad;
      }
      &:last-child {
        fill: #0aadad;
      }
    }
  }
}
@media (min-width: 768px) {
  .total-balance {
    & > div {
      height: 45px;
      padding: 4px 24px;
    }
    svg {
      width: 20px;
    }
  }
}
