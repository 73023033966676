.network-info-detail {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 32px;
  row-gap: 12px;
  @media (min-width: 1360px) {
    justify-content: space-between;
    width: 100%;
  }
  .ant-row {
    height: 100%;
    width: 100%;
    .ant-col-md-16 {
      @media (min-width: 768px) {
        display: flex;
      }
      .network-info-wrap-item {
        .network-info-item {
          @media (min-width: 768px) {
            height: 200px;
          }
        }
      }
    }
    .network-info-wrap-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 4px;
      height: 100%;
      @media (min-width: 516px) {
        height: unset;
      }

      &.active {
        .network-info-item {
          background: linear-gradient(180deg, #013569 0%, #0199bd 100%);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .network-info-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        padding: 8px;
        background: #eefbff;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        min-height: 110px;
        &.network-info-item-firstchild {
          min-height: 240px;
          height: 100%;
          .network-info-item-image {
            width: 32px;
            height: 32px;
          }
          @media (min-width: 768px) {
            min-height: 200px;
          }
        }
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 10px;
          left: 0;
          right: 0;
          bottom: 0;
          background: #42c1c4;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
        @media (min-width: 500px) {
          padding: 8px 16px;
        }
        &:not(:last-child) {
          margin-right: 12px;
        }

        .network-info-item-image {
          margin-bottom: 8px;
          width: 22px;
          height: 22px;
          object-fit: contain;
          img {
            max-width: 100%;
            max-height: 100%;
          }
          @media (min-width: 576px) {
            width: 24px;
            height: 24px;
          }
          @media (min-width: 768px) {
            width: 32px;
            height: 32px;
          }
        }
        .network-info-item-info {
          p {
            &:nth-child(1) {
              font-style: normal;
              font-weight: 700;
              color: #2d3955;
              font-size: 20px;
              line-height: 20px;
              @media (min-width: 576px) {
                font-size: 20px;
                line-height: 24px;
              }
              @media (min-width: 768px) {
                font-size: 24px;
                line-height: 33px;
                margin-bottom: 8px;
              }
            }

            &:nth-child(2) {
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              font-size: 10px;
              margin-bottom: 0;
              color: #2d3955;
              white-space: nowrap;
              @media (min-width: 500px) {
                font-size: 12px;
              }
              @media (min-width: 991px) {
                line-height: 21px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
