@import 'assets/css/variables.scss';
.modal-2fa {
  margin: 0 auto;
  max-width: 100vw;
  padding-bottom: 0;
  .ant-modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    @media (min-width: 768px) {
      border-radius: 16px;
    }
    .ant-modal-header {
      border-radius: 16px;
      padding: 24px 24px;
      @media (min-width: 768px) {
        padding: 48px 24px 0;
        border: none;
      }
    }
    .ant-modal-body {
      text-align: center;
      padding: 0 24px 24px;
      @media (min-width: 768px) {
        padding: 30px 45px;
      }
    }
    .ant-modal-footer {
      padding-bottom: 48px;
      @media (min-width: 768px) {
        padding-bottom: 48px;
      }
    }
  }
  .canvas-qrcode {
    width: 100%;
    height: 100%;
    max-width: 204px;
    max-height: 204px;
    margin: 0 auto 20px;
    border: 2px solid white;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #777e91;
    margin-bottom: 30px;
    .powered-by {
      color: #005e6b;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
    }
    &.title-modal {
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      color: #ffffff;
      margin-bottom: 4px;
    }

    @media (min-width: 991px) {
      font-size: 16px;

      &.title-modal {
        display: none;
      }
    }
  }
  .heading-input-coppy {
    color: #005e6b;
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 8px;
    @media (min-width: 768px) {
      font-size: 20px;
    }
  }
  .text-note {
    color: $text-main;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 24px 0 20px;
    // max-width: 300px;
    margin: 0 auto;
    .gg-authenticator-notice {
      justify-content: center;
      .gg-authenticator-notice-enable {
        font-weight: 700;
      }
    }
  }
}
