@import 'assets/css/variables.scss';

.auth-card {
  width: 100%;
  max-width: 600px;
  padding: 16px 0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-up(lg) {
    padding: 40px;
    background: #f3f3f3;
  }

  .auth-back-icon {
    fill: #30b7bc;
    margin-bottom: 30px;
    cursor: pointer;
    @include media-down(md) {
      margin-bottom: 22px;
    }
  }
  .auth-body {
    @include media-up(md) {
      padding-right: 10px;
      max-height: 420px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
