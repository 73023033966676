.package-wrap-select {
  position: relative;
  width: 100%;

  .ant-select {
    width: 100%;

    .ant-select-selector {
      width: 100%;
      max-width: 100%;
      height: 44px;
      flex: 1;
      background: transparent;
      border: 1px solid rgba(130, 192, 223, 0.3);
      border-radius: 4px;
      outline: none;
      .ant-select-selection-search {
        width: 100%;
        left: 0;
        input {
          color: #000;
          height: 100%;
          &::placeholder {
            color: #000;
          }
        }
      }
      .ant-select-selection-placeholder {
        height: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 44px;
        color: #292929;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #000000;
      }
    }

    .ant-select-arrow {
      display: none;
      svg {
        display: none;
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 30px;
    pointer-events: none;
  }
}
