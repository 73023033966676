@import 'assets/css/variables.scss';

.binary-tree {
  margin-left: 50px;

  &.active {
    .tree-parent {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 22px;
        transform: translateY(calc(-50% + 15px));
        width: 8px;
        height: 8px;
        background: #00da7f;
        border-radius: 50%;
      }
    }
  }

  &.inactive {
    .tree-parent {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 22px;
        transform: translateY(calc(-50% + 15px));
        width: 8px;
        height: 8px;
        background: #848484;
        border-radius: 50%;
      }
    }
  }

  .tree-parent {
    display: flex;
    align-items: center;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      position: absolute;
      width: 28px;
      height: 28px;
      left: -41px;
      top: 45px;

      background: #fdfdfe;
      border-radius: 30px;
      transform: rotate(-90deg);
      z-index: 2;
    }

    .tree-parent-img-start {
      position: absolute;
      width: 12px;
      height: 12px;
      left: -33px;
      top: 52px;
      z-index: 3;
    }
    .tree-parent-name {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 270px;
      padding: 8px;
      margin-top: 30px;
      min-height: 51px;
      background: linear-gradient(0deg, #ffffff, #ffffff), #fefefe;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      z-index: 1;
      @media (min-width: 768px) {
        min-width: 300px;
      }

      &::before {
        content: '';
        position: absolute;
        border-right: 10px solid #5c8cb9;
        left: -32px;
        bottom: 23px;
        height: 10000px;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        border-bottom: 10px solid #5c8cb9;
        top: 23px;
        left: -32px;
        width: 32px;
        height: 1px;
      }

      .tree-parent-name-left {
        display: flex;
        align-items: center;
        .tree-parent-name-img-plus {
          font-size: 23px;
          width: 20px;
          cursor: pointer;
          margin-left: 8px;
          margin-right: 16px;
        }
        .tree-parent-parent-info {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: #000000;
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          text-overflow: ellipsis;
        }
      }
      .tree-parent-name-right {
        background-color: #00da7f;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
        filter: blur(0.5px);
        @media (min-width: 768px) {
          margin-right: 20px;
        }
      }
    }
  }
  .tree-children {
    overflow: hidden;
    @media (min-width: 768px) {
      overflow: unset;
      overflow-y: clip;
    }
  }
}
