@import 'assets/css/variables.scss';

// .go-back {
//   margin: auto;
//   margin-bottom: 30px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   display: none;

//   .back-title {
//     font-weight: 600;
//     font-size: 24px;
//     line-height: 100%;
//     text-transform: capitalize;
//     @media (min-width: 768px) {
//       font-size: 32px;
//     }
//   }
//   p {
//     white-space: nowrap;
//     font-weight: 700;
//     font-size: 20px;
//     line-height: 31px;
//     text-align: right;
//     color: #fff;
//     @media (min-width: 768px) {
//       font-size: 24px;
//     }
//     span {
//       font-weight: 500;
//     }
//   }
//   @media (min-width: 768px) {
//     display: flex;
//     margin-bottom: 48px;
//   }
// }
.email-referal-mobile {
  margin: 24px 0;
  p {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    span {
      font-weight: 500;
    }
  }
}
.tree-root {
  padding: 16px 8px;
  background: #eefbff;
  border-radius: 12px;
  margin-top: 24px;
  border: 1px solid #08a7b7;
  @media (min-width: 768px) {
    margin-top: 16px;
    border-radius: 15px;
    background: #eefbff;
    padding: 58px 32px 0 90px;
  }

  .tree-root-tree {
    height: 600px;
    width: 100%;
    overflow: auto;
    padding-bottom: 60px;
    padding-right: 32px;

    /* Scrollbar */
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(90deg, #cfd1ff 0%, #0d70e3 100%);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #5e6588;
      border-radius: 10px;
    }
  }

  .tree-root-name {
    width: 300px;
    height: 51px;
    padding: 10px 8px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      right: 14px;
      border-radius: 50%;
      background: #00da7f;
      filter: blur(0.5px);
      position: absolute;
      @include media-up(md) {
        right: 30px;
      }
    }
    .tree-root-name-left {
      display: flex;
      align-items: center;
      .tree-root-name-img-plus {
        font-size: 23px;
        width: 20px;
        cursor: pointer;
        margin-left: 8px;
        margin-right: 16px;
      }
      .tree-root-parent-info {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        max-width: 190px;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
      }
    }
  }
  .tree-root-children {
    min-width: 2000px;
    @media (min-width: 768px) {
      min-width: 800px;
      overflow: unset;
    }
  }
}
