@import 'assets/css/variables.scss';
.modal-verification-code {
  top: 25%;
  margin: auto;
  padding-bottom: 0;

  .ant-modal-content {
    padding: 30px 24px;
    .ant-modal-close {
      top: 35px;
      right: 25px;
    }
    p {
      text-align: left;
    }

    .ant-modal-header {
      margin-bottom: 40px;
      padding: 0;
    }

    .ant-modal-body {
      padding: 0;

      .title-modal {
        margin-bottom: 32px;
      }

      .list-steps-verify {
        margin-bottom: 36px;

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          display: flex;
          align-items: center;
          justify-content: center;
          li {
            position: relative;
            padding-left: 30px;

            &.current {
              &::before {
                content: '';
                background: #007ca1;
                border: 1px solid #ffffff;
              }
            }

            &.active {
              &::before {
                content: '✔';
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              height: 18px;
              width: 18px;
              background: #007ca1;
              border: 1px solid #ffffff;
              box-sizing: border-box;
              border-radius: 50%;
              z-index: 1;
            }

            &:last-child {
              padding-bottom: 0;
              margin-left: 30px;
              &::after {
                content: unset;
              }
            }

            p {
              color: $text-main;
              font-weight: 600;
              font-size: 16px;
              line-height: 18px;
              margin-bottom: 0;
              text-transform: uppercase;
            }
          }
        }

        @media (min-width: 991px) {
          ul {
            li {
              p {
                font-size: 18px;
              }
            }
          }
        }
      }

      .modal-verification-code-note {
        color: #007ca1;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 16px;

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .input-code-container {
        margin-bottom: 10px;
        .input-code-item {
          height: 60px;
        }
      }

      @media (min-width: 991px) {
        .modal-verification-code-note {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 11px;
        }

        .input-code-container {
          margin-bottom: 16px;
          .input-code-item {
            height: 80px;
          }
        }
      }
    }

    .modal-scan-action {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }
  // .ant-modal {
  //   max-width: 100vw;
  //   bottom: 0;
  //   top: unset;
  //   position: fixed;
  //   @media (min-width: 576px) {
  //     max-width: unset;
  //     position: unset;
  //   }
  // }
}
