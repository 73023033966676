@import 'assets/css/variables.scss';
.warp-page-deposit {
  width: 100%;
  padding-bottom: 60px;
  .warp-form-deposit {
    width: 100%;
    .deposit-form {
      margin: auto;
      @media (min-width: 768px) {
        background: transparent;
        border-radius: 15px;
        padding: 0;
      }
      // max-width: 1000px;
      .memo-section-wrapper {
        margin-bottom: 30px;
        .title-memo {
          font-weight: 700;
        }
        .token-memo {
          font-weight: 700;
        }
        .memo-section-content {
          align-items: center;
          img {
            width: 40px;
            cursor: pointer;
            &:hover {
              opacity: 0.9;
            }
          }
          // .tooltip {
          //   position: absolute;
          //   bottom: 0;
          //   left: 50%;
          //   transform: translate(-50%, 100%);
          // }
          .note-required {
            background: #3d2600;
            padding: 0 10px;
            position: relative;
            font-size: 14px;
            color: #d3a107;
            &::before {
              content: '';
              position: absolute;
              background: #3d2600;
              width: 10px;
              height: 10px;
              left: 50%;
              top: -5px;
              transform: rotate(45deg);
              @media (min-width: 525px) {
                left: -4px;
                top: 6px;
              }
            }
          }
        }
      }
      .box-show-address-text {
        margin-bottom: 10px;

        > div {
          img {
            margin-top: 20px;
            width: 40px;
            height: 40px;
            &:last-child {
              margin-left: 16px;
            }
          }
        }
        @media (min-width: 576px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            img {
              margin-top: 0;
              width: 50px;
              height: 50px;
            }
          }
        }
        @media (min-width: 991px) {
          margin-bottom: 30px;
        }
      }
      .box-select-deposit {
        margin-bottom: 24px;
        @media (min-width: 991px) {
          margin-bottom: 32px;
        }
        .input-select-item {
          .box-form-control {
            p {
              color: $primary;
              font-size: 16px;
              @media (min-width: 991px) {
                font-size: 20px;
              }
            }
            .ant-select-selector {
              // background: #002a34 ;
              padding-left: 16px;
              @media (min-width: 991px) {
                padding-left: 24px;
              }
            }
            .ant-select-arrow {
              right: 16px;
              @media (min-width: 991px) {
                right: 24px;
                z-index: 6 !important;
              }
            }
          }
        }
        .item-form {
          margin-bottom: 22px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @media (min-width: 991px) {
          .item-form {
            margin-bottom: 35px;
          }
        }
      }

      .box-qr-code-title {
        margin-bottom: 10px;

        p {
          &:first-child {
            color: $primary;
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            margin-bottom: 8px;
            @media (min-width: 768px) {
              margin-bottom: 8px;
            }
          }

          &:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #53a0cc;
            margin-bottom: 0;
            @media (min-width: 768px) {
              font-size: 16px;
            }
          }
        }

        @media (min-width: 991px) {
          p {
            &:first-child {
              font-size: 20px;
              line-height: 27px;
            }

            &:last-child {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }

      .box-qr-code {
        display: flex;
        flex-direction: column;

        .box-qr-code-address {
          background: #141416;
          box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
          border-radius: 12px;
          padding: 10px;
          line-height: 0;
          width: 100px;
          margin: auto;
          margin-bottom: 16px;

          canvas {
            width: 100% !important;
            height: 100% !important;
          }
        }

        .sub-title-qr-code {
          text-align: center;

          p {
            &:first-child {
              font-weight: 600;
              font-size: 14px;
              line-height: 130%;
              color: #ffffff;
              margin-bottom: 4px;
            }

            &:last-child {
              font-weight: 400;
              font-size: 10px;
              line-height: 150%;
              color: #777e91;
              margin-bottom: 0;
            }
          }
        }

        @media (min-width: 991px) {
          background: #23262f;
          padding: 35px 25px;
          border-radius: 16px;

          .box-qr-code-address {
            height: 100%;
            width: 150px;
            order: 2;
            padding: 20px;
          }

          .sub-title-qr-code {
            order: 1;
            margin-bottom: 32px;

            p {
              &:first-child {
                font-size: 18px;
                line-height: 27px;
              }
              &:last-child {
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
        }
      }

      .deposit-box-attention {
        margin-bottom: 24px;

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {
            position: relative;
            padding: 0 0 0 27px;
            margin-bottom: 8px;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 11px;
              height: 11px;
              background: $primary;
              border-radius: 50%;
            }

            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              color: #53a0cc;
              @media (min-width: 768px) {
                font-size: 14px;
                span {
                  font-size: 14px;
                }
              }
              span {
                color: $text-main;
                font-weight: 700;
                font-size: 12px;
              }
            }
          }
        }

        @media (min-width: 991px) {
          margin-bottom: 32px;

          ul {
            li {
              p {
                font-size: 18px;
                span {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .deposit-box-expected {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;

        .item-expected {
          max-width: 50%;
          width: 50%;
          p {
            white-space: nowrap;

            &:first-child {
              color: $text-main;
              font-weight: 400;
              font-size: 12px;
              line-height: 130%;
              margin-bottom: 8px;
              @media (min-width: 768px) {
                font-size: 14px;
              }
            }

            &:last-child {
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              color: #53a0cc;
              margin-bottom: 0;
              @media (min-width: 768px) {
                font-size: 14px;
              }
            }
          }
        }

        @media (min-width: 991px) {
          margin-bottom: 32px;

          .item-expected {
            p {
              &:first-child {
                font-size: 18px;
                line-height: 27px;
              }

              &:last-child {
                font-size: 16px;
              }
            }
          }
        }
      }

      .title-table-history {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
        margin-bottom: 10px;

        @media (min-width: 991px) {
          font-size: 25px;
          margin-bottom: 16px;
        }
      }

      .table-history {
        p {
          margin-bottom: 0;
        }

        .ant-table {
          @media (min-width: 991px) {
            overflow: auto;
          }

          .ant-table-container {
            min-width: 350px;
          }
        }
        .ant-table,
        .ant-table-tbody > tr > td,
        .ant-table-thead > tr > th {
          background: transparent;
        }

        .ant-table-thead > tr > th {
          border-bottom: 1px solid #34384c;

          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #007ca2;
          @media (min-width: 991px) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .ant-table-tbody > tr > td {
          border-bottom: unset;
        }

        .ant-table-thead > tr > th:last-child,
        .ant-table-tbody > tr > td:last-child {
          text-align: right;
          padding-right: 0;
        }

        .ant-table-thead > tr > th:first-child,
        .ant-table-tbody > tr > td:first-child {
          padding-left: 0;
        }

        .time {
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: #a5adcf;
        }

        .amount {
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          text-align: right;
          color: #ffffff;
        }

        .box-action-txid {
          > div {
            &:first-child {
              display: flex;
              align-items: center;

              p {
                margin-bottom: 0;

                &:first-child {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 150%;
                  color: #ffffff;
                  margin-right: 16px;
                }

                &:last-child {
                  font-weight: 700;
                  font-size: 8px;
                  line-height: 100%;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 62px;
                  height: 15px;
                  border-radius: 3px;

                  &.pending {
                    color: #f9aa4b;
                    background: #ffe6c8;
                  }

                  &.completed {
                    color: #44c99c;
                    background: #d5f3e9;
                  }
                }
              }
            }
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #ffffff;
          }

          @media (min-width: 991px) {
            > div {
              &:first-child {
                p {
                  &:last-child {
                    font-size: 12px;
                    line-height: 16px;
                    width: 95px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }

        .action {
          color: #ffffff;
        }

        .txid {
          color: #ffffff;
        }

        @media (min-width: 991px) {
          .time {
            font-size: 16px;
            line-height: 24px;
          }

          .amount {
            font-size: 16px;
            line-height: 24px;
          }

          .action {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }

          .txid {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }

      @media (min-width: 991px) {
        // padding: 55px 90px;

        .deposit-form-top {
          row-gap: 10px !important;
        }
      }
    }
  }
}
