@import 'assets/css/variables.scss';
.form-change-password {
  z-index: 2222;
  margin: 0 auto;
  max-width: 100vw;
  padding-bottom: 0;
  @include media-up(md) {
    margin: 0;
    position: relative;
    max-width: 580px;
    width: 100%;
  }

  .form-change-password-heading {
    font-size: 20px;
    text-align: center;
    margin-top: 24px;
    font-weight: 600;
    color: $primary;
    @include media-up(lg) {
      font-size: 32px;
      margin-top: 48px;
    }
  }
  .verify-input {
    .verify-resend {
      display: flex;
      margin-top: 14px;
      justify-content: space-between;
      .time-count {
        color: $text-main;
        font-weight: 500;
        text-align: left;
      }
      a {
        font-weight: 600;
        color: $link;
      }
    }
    .verify-error {
      color: #ff4d4f;
    }
  }
  .ant-modal-header {
    border-radius: 12px;
  }
  .ant-modal-content {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
    @include media-up(lg) {
      border-radius: 12px !important;
    }
    .ant-modal-body {
      .verify-action-change-passwrord {
        button {
          background: #069cc9;
          color: #fff;
          border-radius: 12px;
          width: 130px;
          height: 45px;
          padding: 0;
        }
      }
      @include media-up(lg) {
        padding: 24px 64px 64px;
      }
      .ant-form-vertical {
        .form-item-input {
          .wrapper-input {
            background: #fbfbfb;
            border: 1px solid #33b5ff;
            border-radius: 8px;
          }
          .ant-form-item-label {
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}
