/*
* Dropdown select
*/
.ant-select-dropdown {
  min-width: 120px !important;
  background-color: #ffffff;
  .ant-select-item {
    .ant-select-item-option-content {
      font-size: 12px;
      @media (min-width: 768px) {
        font-size: 16px;
      }
      padding: 6px 0;
      img {
        width: 40px;
      }
    }
  }
  .ant-select-item-option-selected {
    background-color: #f5f5f5;
  }
}
