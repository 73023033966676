@import 'assets/css/variables.scss';

.wrapper-range-picker-date-to-date {
  background: #eefbff;
  border: 1px solid #069cc9;
  border-radius: 12px;
  // height: 45px;
  // @media (min-width: 768px) {
  //   height: 50px;
  // }
  &.ant-space {
    position: relative;
    display: block;
    .ant-space-item {
      &:first-child,
      &:nth-child(2) {
        margin-bottom: 0 !important;
      }
      .ant-picker-suffix {
        display: none;
      }
    }

    .calendar {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      width: 15px;
      path {
        fill: #069cc9;
      }
      @media (min-width: 400px) {
        width: 22px;
      }
    }

    .dropdown {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      width: 10px;
      transition: 0.25s;
      z-index: 1;
      path {
        fill: #069cc9;
      }
      @media (min-width: 400px) {
        width: 14px;
      }
    }

    .ant-picker {
      border: none;
      width: 100%;
      height: 45px;
      padding: 4px;
      max-width: 100%;
      background: transparent;
      @media (min-width: 768px) {
        height: 50px;
      }
      &.ant-picker-focused {
        border: none;
        box-shadow: none;
      }
      .ant-picker-input {
        > input {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: $text-main;
        }

        &:nth-child(1) {
          > input {
            text-align: right;
          }
        }

        &:nth-child(3) {
          > input {
            text-align: left;
          }
        }
      }

      .ant-picker-range-separator {
        padding: 0;

        .anticon-swap-right {
          position: relative;

          &::after {
            content: '-';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: inline-block;
            color: #fff;
          }

          svg {
            opacity: 0;

            path {
              fill: #ffffff;
            }
          }
        }
      }
    }
  }
}
.ant-picker-dropdown {
  .ant-picker-panels {
    display: flex;
    max-width: fit-content;
    @include media-down(md) {
      flex-wrap: wrap;
    }
  }
  table {
    &.ant-picker-content {
      min-width: unset;
    }
  }
}
