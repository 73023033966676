@import 'assets/css/variables.scss';
.modal-exchange {
  position: relative;
  padding: 46px 32px;
  @include media-down(sm) {
    padding: 0;
  }
  .ant-modal-content {
    padding: 32px 24px;
  }
  .ant-modal-body {
    p {
      color: #000;
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: -0.011em;
      text-align: center;
      max-width: 370px;
      margin: 0 auto;
      @include media-down(sm) {
        font-size: 16px;
      }
      span {
        color: #44c99c;
      }
    }
  }

  .modal-exchange-actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
      color: #ffffff;
      font-weight: 700;
      font-size: 16px;
      background: #069cc9;
      border-radius: 12px;
      max-width: 200px;
    }
  }
}
