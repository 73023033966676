@import 'assets/css/variables.scss';
.control-input-text-item {
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: $text-main;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 8px;
  }

  input {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    height: 45px;
    width: 100%;
    background: $bg-input;
    border: 1px solid $border-input;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 24px;
    outline: none;
    color: $secondary;
    &::placeholder {
      color: #007ca1;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {
      color: #007ca1;
    }
    &::-ms-input-placeholder {
      color: #007ca1;
    }
    @media (min-width: 768px) {
      height: 50px;
    }
  }

  @media (min-width: 991px) {
    // margin-bottom: 32px;
    width: 100%;

    input {
      font-size: 16px;
    }

    p {
      font-size: 20px;
      line-height: 27px;
    }
  }
}
