@import 'assets/css/variables.scss';

.wrapper-sign-form {
  height: 100%;
  min-width: 100vw;
  min-height: 670px;

  background-image: url(../../../../assets/images/background02.png);
  background-size: cover;
  background-position: center 20%;

  @include media-down(md) {
    min-height: unset;
    background-image: url(../../../../assets/images/bg-mobile.png);
  }

  .sign-email-content {
    width: 100%;
    min-height: calc(100vh - 101px);
    max-width: 1290px;
    margin: 0 auto;
    padding: 0 14px 14px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-down(md) {
      padding: 14px;
      flex-direction: column;
      justify-content: center;
    }

    .decorate-sign-left {
      width: 50%;
      padding: 20px;
      z-index: 10;

      img {
        width: 100%;
        min-width: 600px;
        margin-left: -60px;
      }
    }

    .decorate-sign-left-mobile {
      z-index: 10;
      img {
        width: 240px;
      }
    }

    .card-sign-right {
      width: 50%;
      z-index: 10;
      @include media-down(md) {
        width: 100%;
      }
    }
  }
}
