@import 'assets/css/variables.scss';

.modal-confirm-buy-nft {
  width: 536px;
  min-height: 219px;
  background: #eefbff;
  border-radius: 12px;
  border: 1px solid #08a7b7;
  .ant-modal-content {
    background: none;
    border: none;
    box-shadow: none;
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 20px;
      .modal-confirm-heading {
        color: #007ca2;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
      }
      .modal-confirm-body {
        margin-top: 16px;
        text-align: center;
        & > p {
          color: #292929;
          font-weight: 700;
          font-size: 16px;
          line-height: 140%;
          &:last-child {
            margin-top: 16px;
          }
        }
      }
      .modal-confirm-button {
        text-align: center;
        margin: 24px auto 0;
        display: flex;
        justify-content: center;
        button {
          height: 46px;
          background: linear-gradient(135deg, #d2f2fc 0%, #0aadad 100%);
          border-radius: 12px;
          border: none;
          width: 102px;
          padding: 0;
          margin: 0 12px;
          &.modal-confirm-button-cancel {
            background: #bd1207;
            border-radius: 12px;
            color: #fff;
          }
        }
      }
      .options-currency {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        & > .options-currency-label {
          font-size: 16px;
          font-weight: bold;
          margin-right: 6px;
        }
        .ant-select-selector {
          width: 100px;
          text-align: left;
          border-radius: 10px;
        }
      }
      .package-detail-price {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 6px;
        p {
          color: #292929;
          white-space: nowrap;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          margin: 0;
        }
      }
      .error-mess {
        color: $warning;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}

.options-currency-dropdown {
  .ant-select-item {
    .ant-select-item-option-content {
      font-size: 14px;
    }
  }
}
