@import 'assets/css/variables.scss';

.wallet-container {
  width: 100%;
  padding: 50px 15px 0;
  @include media-down(md) {
    padding-top: 0;
  }
  .wallet-header {
    display: flex;
    justify-content: space-between;
  }

  .wallet-header-action {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    @include media-up(md) {
      justify-content: flex-end;
    }

    button {
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      text-align: center;
      text-transform: capitalize;

      height: 32px;
      min-width: 100px;
      border-radius: 23px;
      background: transparent;
      border: 1px solid #535769;
      cursor: pointer;
      transition: 0.3s ease;

      &:not(:first-child) {
        margin-left: 14px;
      }

      &:hover {
        color: #000000;
        background: #ffffff;
        border: 1px solid #ffffff;
      }

      &[data-active='true'] {
        color: #000000;
        background: #ffffff;
        border: 1px solid #ffffff;
      }
    }
  }

  .wallet-content {
    padding: 0;

    .wallet-right {
      display: flex;
      padding: 0px;
      img.wallet-decorate {
        width: 90%;
        margin-top: -70px;
        object-fit: contain;
      }
    }

    .wallet-br-01 {
      border-top: 0.1px solid #3fbdbe;
      margin-bottom: 50px;
      @include media-down(md) {
        display: none;
      }
    }
  }
}
