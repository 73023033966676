@import 'assets/css/variables.scss';
.table-item-member-desktop {
  .table-item-member-desktop-table {
    width: 100%;
    text-align: center;
    min-width: 500px;

    .table-heading-item-member,
    .table-td-item-member {
      padding: 10px 12px;
      font-style: normal;
      line-height: 130%;
      color: #2d3955;
    }

    .table-heading-item-member {
      border-bottom: 1px solid #000;
      padding: 16px 0;
      font-weight: 700;
      font-size: 16px;
    }

    .table-td-item-member {
      span {
        font-weight: 500;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        line-height: 130%;
        padding: 4px 16px;
        width: 149px;

        &[data-status='true'] {
          background: #00930f;
          border-radius: 4px;
          color: #fff;
          font-weight: 700;
        }
        &[data-status='Pending'] {
          background: #fdc22a;
          border-radius: 4px;
          color: #fff;
          font-weight: 700;
        }
        &[data-status='False'] {
          color: #e90000;
          color: #f9aa4b;
          background: #ffc8c8;
        }
      }
      &.item-package {
        font-weight: 700;
      }
    }
    tbody {
      &.network-wrap-table-tbody-empty {
        position: relative;
        height: 300px;
        .network-table-mon-data {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &.network-wrap-table-tbody {
        > tr {
          border-bottom: 1px solid #007ca270;
          .table-td-item-member {
            padding: 16px 0;
          }
        }
      }
    }
  }
}
