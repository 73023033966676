@import 'assets/css/variables.scss';

.amount-in {
  color: $in-color;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}
.amount-out {
  color: $out-color;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}
