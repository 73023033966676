@import 'assets/css/variables.scss';

.warp-page-primary-kyc {
  .page-primary-kyc-content {
    padding: 8px;
    border-radius: 30px;

    @media (min-width: 991px) {
      background: #eefbff;
      padding: 32px;
    }

    .go-back {
      display: none;
      @media (min-width: 991px) {
        display: flex;
      }
    }
    .primary-kyc-content-form {
      @media (min-width: 768px) {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 12px;
      }
      .box-input-select {
        margin-bottom: 24px;

        .input-select-item {
          p {
            font-weight: 700;
            color: #292929;

            @media (min-width: 991px) {
              font-weight: 600;
            }
          }
        }
      }

      .box-input-text {
        margin-bottom: 0px;

        .ant-form-item {
          width: 100%;
        }

        .form-item-calendar {
          .ant-form-item-control-input-content {
            p {
              color: $text-main;
              font-size: 16px;
              margin-bottom: 8px;
              line-height: 27px;
            }

            > div {
              display: flex;
              align-items: flex-end;
            }
          }

          .ant-picker {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #069cc9;
            padding: 10px 16px;
            width: 100%;
            background: #f5fefe;
            border: 1px solid #069cc9;
            outline: none;
            border-radius: 8px;
            height: 45px;
            @media (min-width: 768px) {
              height: 50px;
            }
            .ant-picker-suffix {
              color: #97b8fe;
            }
            .anticon-calendar {
              color: #97b8fe;
            }
          }

          .ant-picker-input > input {
            color: $primary;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;

            &::placeholder {
              color: rgba(94, 137, 161, 0.8);
            }
          }
        }
      }

      .box-input-file {
        margin-bottom: 32px;

        .primary-kyc-update-card-photo {
          display: flex;
          flex-direction: column;
          img {
            width: 100%;
          }
        }

        .primary-kyc-update-card-photo-title {
          .input-file-item-title {
            color: $text-main;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 8px;
          }

          .input-file-item-description {
            color: $text-main;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            margin-bottom: 32px;
            span {
              color: #01acc3;
            }
          }
        }

        .input-file-item {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 32px;
          }

          .top-sub {
            display: none;
          }

          .input-file-item-title {
            color: $text-main;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 8px;
          }

          .input-file-item-description {
            color: $text-main;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            margin-bottom: 32px;
            span {
              color: #01acc3;
            }
          }

          .ant-upload.ant-upload-drag {
            background-color: transparent;
            border: 2px dashed #a5a5a5;
            border-radius: 24px;

            img {
              max-width: 100%;
              @media (min-width: 768px) {
                max-height: 200px;
              }
            }
          }

          .ant-upload.ant-upload-btn {
            display: flex !important;
            align-items: center;
            justify-content: center;
            padding: 50px;
          }

          .ant-upload-drag-icon {
            margin-bottom: 18px;

            img {
              max-width: 100%;
            }
          }

          .ant-upload-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            font-feature-settings: 'cv11' on, 'cv02' on, 'cv03' on, 'cv04' on;
            color: $primary;
            span {
              color: $text-main;
            }
          }

          .box-input {
            max-width: 100%;
            justify-content: center;

            .image-example {
              display: none;
            }
          }
        }
      }

      .reject-reason {
        color: yellow;

        width: 100%;
        min-height: 50px;
        max-width: 500px;
        margin: 0 auto 30px;
        padding: 20px 0;

        background: #3b4c37;
        border-radius: 12px;
      }
    }
  }

  @media (min-width: 991px) {
    .page-primary-kyc-content {
      .go-back {
        margin-bottom: 32px;
        color: #ffffff;
        .back-link {
          align-items: center;
        }
      }

      .primary-kyc-content-form {
        .box-input-text {
          margin-bottom: 32px;
          display: flex;
          flex-wrap: wrap;

          .ant-row {
            .ant-col-24.ant-col-lg-12 {
              &:first-child {
                padding-right: 16px;
              }

              &:last-child {
                padding-left: 16px;
              }
            }
          }

          .input-text-item {
            &:nth-child(2),
            &:nth-child(3) {
              max-width: 50%;
            }

            &:nth-child(2) {
              padding-right: 8px;
            }

            &:nth-child(3) {
              padding-left: 8px;
            }
          }

          .form-item-calendar {
            .ant-form-item-control-input-content {
              p {
                font-size: 20px;
                margin-bottom: 16px;
              }
            }
            .ant-picker-suffix {
              color: #97b8fe;
              svg {
                font-size: 20px;
                cursor: pointer;
              }
            }
            .anticon-calendar {
              color: #97b8fe;
            }
          }
        }

        .box-input-file {
          margin-bottom: 48px;

          .primary-kyc-update-card-photo {
            display: flex;
            flex-direction: row;
            margin-bottom: 48px;

            .input-file-item {
              padding-right: 32px;
              width: 100%;
              max-width: 50%;

              &:last-child {
                padding-right: 0;
                padding-left: 32px;
              }
            }
          }

          .primary-kyc-update-card-photo-title {
            .input-file-item-title {
              font-size: 20px;
              line-height: 27px;
            }

            .input-file-item-description {
              font-size: 16px;
              line-height: 24px;
              span {
                color: #01acc3;
              }
            }
          }

          .input-file-item {
            margin-bottom: 0 !important;

            .top-sub {
              display: block;

              font-weight: 600;
              font-size: 18px;
              line-height: 150%;
              font-feature-settings: 'cv11' on, 'cv02' on, 'cv03' on, 'cv04' on;
              color: #ffffff;
            }

            .input-file-item-title {
              font-size: 18px;
              line-height: 27px;
            }

            .input-file-item-description {
              font-size: 16px;
              line-height: 24px;
            }

            .ant-upload.ant-upload-drag {
              border: 2px dashed #a5a5a5;
              border-radius: 15px;
            }

            .ant-upload-text {
              font-size: 18px;
            }

            &:last-child {
              .box-input {
                .ant-upload {
                  display: block;
                  @include media-up(lg) {
                    max-width: 100%;
                    img {
                      width: 100%;
                    }
                  }

                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .page-primary-kyc-content {
      .primary-kyc-content-form {
        .box-input-file {
          .input-file-item {
            .box-input {
              display: flex;
              > span {
                padding-right: 40px;
                display: flex;
                align-items: stretch;
                flex: 0 0 50%;
                max-width: 50%;
              }

              .image-example {
                padding-left: 40px;
                display: flex;
                align-items: center;
                flex: 0 0 50%;
                max-width: 50%;

                p {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #ffffff;
                  margin-right: 16px;
                }

                img {
                  width: 100%;
                  padding: 80px;
                  max-width: 100%;
                  background: rgba(255, 255, 255, 0.03);
                  border: 2px dashed #a5a5a5;
                  border-radius: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
