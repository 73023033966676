@import 'assets/css/variables.scss';

.wFooter {
  width: 100%;
  min-height: 70px;
  display: block;

  ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    width: 100%;
    list-style: none;
    margin: 0;

    li {
      padding: 20px;

      a {
        color: #069fc2;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        &:hover {
          color: #000;
        }
      }
    }
  }
}
