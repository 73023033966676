@import 'assets/css/variables.scss';

.input-confirm {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fbfbfb;
  border: 1px solid #33b5ff;
  border-radius: 8px;
  color: #ffffff;
  outline: unset;

  &[data-size='small'] {
    padding: 8px 8px 8px 16px;
    height: 18px;
    @include media-up(sm) {
      height: 26px;
    }
  }
  &[data-size='standard'] {
    padding: 8px 24px 8px 24px;
    height: 45px;
    @include media-up(sm) {
      height: 50px;
    }
  }
  &[data-size='larger'] {
    padding: 8px 8px 8px 24px;
    height: 56px;
    @include media-up(sm) {
      height: 66px;
    }
  }

  input {
    color: rgba(41, 41, 41, 0.7);
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;

    width: 100%;
    height: 100%;
    background: transparent;
    border: unset;
    outline: unset;
    @include media-up(md) {
      font-size: 16px;
    }
    &::placeholder {
      color: #292929;
      font-size: 12px;
      @include media-up(md) {
        font-size: 14px;
      }
    }
    &:hover,
    &:valid {
      outline: unset;
    }
  }
  svg {
    width: 15px;
    margin-left: 10px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }
}
