@import 'assets/css/variables.scss';
.header-account-view {
  height: 72px;
  margin: 0 auto;
  padding: 0 15px;
  background: #020c2fd8;
  border-bottom: 2px solid #0f3d6e;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  align-items: center;
  @include media-up(md) {
    margin-left: unset;
    margin-right: unset;
  }
  &.header-account-view1 {
    justify-content: flex-end;
  }
  &.header-account-view2 {
    justify-content: space-between;
  }
  @include media-up(md) {
    display: none;
  }
  > h2 {
    color: #ffffff;
    top: 50%;
    left: 50%;
    position: absolute;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    transform: translate(-50%, -50%);
  }
  .header-account-view1-edit {
    &.header-account-view1-edit-setting {
      &:hover {
        svg {
          transform: rotate(50deg) scale(1.1);
        }
      }
    }
    &.header-account-view1-save {
      &:hover {
        svg {
          transform: scale(1.1);
        }
      }
    }
    > svg {
      width: 19px;
      height: 19px;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
    }
  }
  .header-account-view1-save {
    > svg {
      width: 21px;
      height: 21px;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
    }
  }
}
.warp-page-account {
  width: 100%;
  .page-account-content {
    margin: auto;
    max-width: 100%;
    border-radius: 15px;
    @media (min-width: 991px) {
      background: #eefbff;
      border-radius: 12px;
      padding: 32px;
    }
    .box-avatar {
      display: flex;
      max-width: 100%;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(
        265.29deg,
        rgba(255, 255, 255, 0) -3.06%,
        rgba(255, 255, 255, 0.0990749) 32.73%,
        rgba(255, 255, 255, 0.15) 66.96%,
        rgba(255, 255, 255, 0) 114.42%
      );
      border-radius: 0px 0px 15px 15px;
      margin-bottom: 36px;
      @media (min-width: 991px) {
        padding: 30px 0;
        background: transparent;
        flex-direction: row;
      }
      .avatar-content-left {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        @media (min-width: 991px) {
          flex-direction: row;
        }
        .avatar-image {
          padding: 2px;
          position: relative;
          border-radius: 128px;
          height: 150px;
          width: 150px;
          border: 2px solid $primary;
          @media (min-width: 991px) {
            height: 100px;
            width: 100px;
            border: none;
          }

          img {
            &:first-child {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 128px;
              background: transparent;
              border: 2px solid $secondary;
              @media (min-width: 768px) {
                background: #5575b3;
              }
            }

            &:last-child {
              right: -10px;
              bottom: -5px;
              width: 28px;
              cursor: pointer;
              position: absolute;
              transform: translate(-50%, -50%);
              @media (min-width: 991px) {
                top: 50%;
                left: 50%;
                width: 14px;
                transform: translate(-50%, -50%);
              }
              &:active {
                opacity: 0.7;
              }
            }
          }
        }
        .wrapper-info {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 16px;
          @media (min-width: 991px) {
            margin-top: 0px;
            margin-left: 24px;
            justify-content: space-between;
          }
          .avatar-info {
            width: 70%;
            &.avatar-info-edit {
              .edit {
                margin-left: 35px;
                @media (min-width: 768px) {
                  margin-left: 0;
                }
                input {
                  &:nth-child(1) {
                    text-align: right;
                    padding-right: 8px;
                    @media (min-width: 768px) {
                      text-align: left;
                    }
                  }
                  &:nth-child(2) {
                    padding-left: 8px;
                  }
                }
                img {
                  width: 12px;
                  @media (min-width: 768px) {
                    width: 20px;
                  }
                }
              }
            }
            input {
              width: 100%;
              max-width: 120px;
              background-color: transparent;
              border: none;
              color: #292929;
              font-size: 12px;
              font-weight: 700;
              background: transparent;
              @media (min-width: 768px) {
                font-size: 32px;
                max-width: fit-content;
              }
              &:hover,
              &:focus {
                outline: none;
              }
            }
            p {
              display: flex;
              flex-wrap: nowrap;
              justify-content: center;
              @media (min-width: 991px) {
                justify-content: flex-start;
              }
              &:first-child {
                text-align: center;
                align-items: center;
                font-weight: 700;
                font-size: 12px;
                line-height: 140%;
                color: $text-main;
                margin-bottom: 4px;
                @media (min-width: 991px) {
                  text-align: left;
                  display: flex;
                  font-size: 32px;
                  line-height: 27px;
                  color: $secondary;
                  margin-bottom: 8px;
                }
                img {
                  margin-left: 8px;
                  width: 20px;
                  cursor: pointer;
                  @media (min-width: 991px) {
                    margin-left: 16px;
                    transform: translate(-10%, 4%);
                  }
                  &:active {
                    opacity: 0.7;
                  }
                }
              }

              &:last-child {
                font-weight: 400;
                font-size: 16px;
                line-height: 21px;
                color: $text-main;
                margin-bottom: 0;
                img {
                  width: 20px;
                  cursor: pointer;
                  @media (min-width: 991px) {
                    margin-left: 10px;
                    transform: translate(-10%, 0%);
                  }
                  &:active {
                    opacity: 0.7;
                  }
                }
              }
            }
          }
          .btn-logout-mobile {
            display: block;
            @media (min-width: 991px) {
              display: none;
            }
          }
        }
      }

      .avatar-content-right {
        display: none;
      }

      @media (min-width: 991px) {
        .avatar-content-left {
          .avatar-image {
            height: 85px;
            width: 100px;
          }

          .avatar-info {
            p {
              &:first-child {
                font-size: 18px;
                margin-bottom: 8px;
              }

              &:last-child {
                font-size: 14px;
              }
            }
          }
        }

        .avatar-content-right {
          display: block;
          button {
            background: linear-gradient(135deg, #d2f2fc 0%, #0aadad 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
        .avatar-content-right-save {
          display: block;
          > button {
            background: linear-gradient(135deg, #d2f2fc 0%, #0aadad 100%);
            border-radius: 12px;
            color: #fff;
            -webkit-text-fill-color: unset;
            path {
              fill: none;
            }
          }
        }
      }
    }
    .ref-view {
      margin-bottom: 30px;
    }
    .box-info-input {
      margin-bottom: 32px;

      .input-width-pen {
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 27px;
          color: #292929;
          margin-bottom: 0;
        }

        .box-input {
          position: relative;
          max-width: 100%;
          p {
            font-size: 18px;
            font-weight: 600;
          }
          .ant-form-item {
            @media (min-width: 991px) {
              margin-bottom: 32px;
            }
            .ant-form-item-label {
              padding-bottom: 8px;
              @media (min-width: 768px) {
                padding-bottom: 16px;
              }
              span {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 16px;
                @media (min-width: 991px) {
                  font-size: 20px;
                }
              }
            }
          }
          input {
            border-radius: 8px;
            height: 48px;
            width: 100%;
            padding-left: 12px;
            padding-right: 40px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #292929;
            background: $bg-input;
            border: 0.5px solid #069cc9;
            @media (min-width: 768px) {
              height: 50px;
              background: #fbfbfb;
            }
            // &.input-phone {
            //   padding-left: 68px;
            // }
          }
          .code-phone {
            position: absolute;
            left: 20px;
            top: 37px;
            width: 45px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 700;
            z-index: 2;
            @media (min-width: 768px) {
              top: 45px;
              font-size: 16px;
            }
            &:after {
              top: 0;
              width: 2px;
              left: 45px;
              content: '';
              height: 24px;
              position: absolute;
              background: $text-main;
            }
          }

          img {
            position: absolute;
            right: 20px;
            top: 50px;
            // top: 50%;
            transform: translateY(-50%);
            width: 16px;
            cursor: pointer;
            @media (min-width: 768px) {
              top: 58px;
            }
          }

          .ant-select-selector {
            width: 100%;
            height: 50px;
            color: #292929;
            font-weight: 500;
            font-size: 14px;
            border-radius: 8px;
            background: $secondary;
            border: 1px solid #33b5ff;
            @media (min-width: 768px) {
              background: #fbfbfb;
              height: 52px;
            }
            > .ant-select-selection-search {
              right: 0 !important;
              left: 0 !important;
            }
            .ant-select-selection-item {
              line-height: 50px;
            }

            input {
              border: unset;
            }
          }
          .ant-select-arrow {
            color: #292929;
            right: 24px;
            z-index: 6;
          }
          .ant-picker {
            background: $bg-input;
            border: 0.5px solid #069cc9;
            border-radius: 8px;
            color: #292929;
            @media (min-width: 768px) {
              background: #fbfbfb;
            }
            .ant-picker-suffix,
            .ant-picker-clear {
              opacity: 0;
              display: none;
            }

            input {
              background-color: transparent;
              border: none;
            }
          }
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 42px;

        .input-width-pen {
          p {
            font-size: 20px;
            margin-bottom: 16px;
          }

          .box-input {
            p {
              font-size: 20px;
              margin-bottom: 16px;
            }
            input {
              padding-left: 24px;
              padding-right: 45px;
              font-size: 16px;
            }

            img {
              width: 18px;
            }

            .ant-select-selector {
              font-size: 18px;
            }
          }
        }
      }
    }
    .box-info-password {
      display: flex;
      justify-content: space-between;
      margin-bottom: 27px;
      position: relative;
      .password-left {
        width: 100%;
        p {
          &:first-child {
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #292929;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &:last-child {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #292929;
            opacity: 0.7;
          }
        }
      }

      .password-right {
        position: absolute;
        right: 0;
        button {
          background: #069cc9;
          border-radius: 12px;
          width: 80px;
          height: 30px;
          color: #fff;
          padding: 0;
          @media (min-width: 768px) {
            width: 102px;
            height: 46px;
          }
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 32px;
        .box-info-password {
          align-items: center;
          .password-left {
            p {
              &:first-child {
                font-size: 20px;
                margin-bottom: 8px;

                button {
                  display: none;
                }
              }

              &:last-child {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }

          .password-right {
            position: unset;
          }
        }
      }
    }
    .box-rate-sercurity {
      // margin-bottom: 45px;

      > a {
        &:last-child {
          .rate-sercurity-item {
            margin-bottom: 0;
          }
        }
      }
      .rate-sercurity-item {
        background: #b0dae7;
        border-radius: 8px;
        backdrop-filter: blur(300px);
        padding: 24px 20px;
        margin-bottom: 36px;
        @media (min-width: 768px) {
          background: #b0dae7;
          border-radius: 8px;
        }
        .rate-sercurity-item-name {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          p {
            font-weight: 700;
            font-size: 16px;
            line-height: 27px;
            color: #292929;
            margin-left: 12px;
          }
          img {
            width: 18px;
          }
        }

        .rate-sercurity-item-description {
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #292929;
          }
        }
      }
      .btn-edit-mobile {
        justify-content: center;
        button {
          width: 94px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $secondary;
          color: #ffff;
        }
      }
      .btn-save-mobile {
        justify-content: center;
        display: flex;
        button {
          width: 94px;
          height: 36px;
          color: #fff;
          background: #069cc9;
          border-radius: 8px;
          @media (min-width: 768px) {
            width: 94px;
            height: 36px;
          }
        }
      }
      @media (min-width: 991px) {
        margin-bottom: 0;

        .rate-sercurity-item {
          padding: 32px;

          .rate-sercurity-item-name {
            margin-bottom: 24px;
            img {
              width: 30px;
              object-fit: contain;
            }
            p {
              font-weight: 700;
              margin-left: 15px;
              font-size: 16px;
            }
          }

          .rate-sercurity-item-description {
            p {
              font-size: 16px;
              line-height: 24px;
              color: #292929;
            }
          }
        }
      }
    }

    .log-out {
      text-align: center;

      @media (min-width: 991px) {
        display: none;
      }
    }
  }
}

.dropdown-country {
  .ant-empty-description {
    color: #000;
  }
}
