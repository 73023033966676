@import 'assets/css/variables.scss';

.form-forgot {
  max-width: 551px;
  @include media-up(md) {
    padding: 24px;
  }
  @include media-up(lg) {
    padding: 48px;
  }
  .form-action-forgot {
    width: 100%;
    margin: 16px auto;

    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    @include media-up(sm) {
      max-width: 287px;
      margin: 24px auto 6px;
    }
  }

  .form-links {
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: $text-main;
    @include media-up(sm) {
      font-size: 16px;
    }
    a {
      color: $link;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      margin-left: 5px;

      @include media-up(sm) {
        font-size: 16px;
      }
      &:hover {
        opacity: 0.7;
      }
      &:active {
        opacity: 1;
      }
    }
  }
}
