@import 'assets/css/variables.scss';

// Form item Input
.ant-form-item.form-item-input {
  // &:not(.ant-form-item-with-help) {
  //   margin-bottom: 16px;
  //   @include media-up(md) {
  //     margin-bottom: 24px;
  //   }
  // }
  // .ant-form-item-explain,
  // .ant-form-item-extra {
  //   min-height: 16px;
  //   transition: unset !important;
  // }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  label {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .wrapper-label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    span {
      color: $text-main;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      // margin-bottom: 8px;

      @include media-up(sm) {
        font-size: 18px;
      }
    }

    &.ant-form-item-required span {
      position: relative;

      &:before {
        content: '*';
        color: $primary;
        display: inline-block;
        font-size: 13px;
        line-height: 1;
        position: absolute;
        right: -12px;
      }
    }

    button {
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      text-align: center;

      max-width: 80px;
      height: 30px;
      padding: 0 20px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @include media-up(sm) {
        font-size: 14px;
        height: 40px;
        max-width: 110px;
      }

      &:hover {
        opacity: 0.7;
      }

      &:active {
        opacity: 1;
      }
    }

    .btn-active {
      color: #ffffff;
      background: transparent;
      border: 1px solid #a5adcf;
      border-radius: 50px;
      cursor: pointer;
    }

    .btn-disable {
      color: #fa2256;
      background: transparent;
      border: 1px solid #fa2256;
      border-radius: 50px;
      cursor: pointer;
    }
  }

  .ant-form-item-explain-error {
    font-size: 12px;

    @include media-up(sm) {
      font-size: 14px;
    }
  }
}
