@import 'assets/css/variables.scss';

.verify-active-account-code {
  .wrap-logo-mb {
    position: relative;
    text-align: center;
    @media (min-width: 768px) {
      display: none;
    }
    .logo {
      height: auto;
      max-width: 107px;
      max-height: 107px;
      object-fit: contain;
    }
  }
  .verify-title {
    color: $text-main;
    font-weight: 700;
    font-size: 20px;
    line-height: 44px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 32px;

    @include media-up(sm) {
      font-size: 28px;
    }
  }
  .verify-resend {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;

    > div {
      color: $text-main;
    }
    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
    }
  }
  .verify-error {
    color: $warning;
    font-size: 13px;
    text-align: center;
    padding: 20px 10px;
    @include media-up(sm) {
      font-size: 16px;
    }
  }
  .verify-action {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    row-gap: 20px;

    button {
      max-width: 100%;
    }
  }
}
