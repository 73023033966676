@import 'assets/css/variables.scss';

.ant-checkbox-wrapper.w-checkbox-small {
  span:last-child {
    color: rgba($text-main, 0.7);

    font-weight: 400;
    line-height: 24px;
    font-size: 14px;
  }

  .ant-checkbox-inner {
    background: #f5fefe;
    border: 0.5px solid #069cc9;
    border-radius: 5px;

    &::after {
      width: 5px;
      height: 8px;
      top: 50%;
      left: 26%;
      border: 2px solid #8492ff;
      border-top: 0;
      border-left: 0;
    }
  }

  input,
  .ant-checkbox-checked::after,
  .ant-checkbox-inner {
    width: 16px;
    height: 16px;
  }

  .ant-checkbox-checked {
    &::after {
      border-radius: 5px;
    }
  }
}

.ant-checkbox-wrapper.w-checkbox-standard {
  span:last-child {
    color: rgba($text-main, 0.7);

    font-weight: 400;
    line-height: 24px;
    font-size: 14px;

    @include media-up(md) {
      font-size: 16px;
    }
  }

  .ant-checkbox-inner {
    background: #f5fefe;
    border: 0.5px solid #069cc9;
    border-radius: 5px;

    &::after {
      width: 7px;
      height: 11px;
      top: 50%;
      left: 26%;
      border: 3px solid #8492ff;
      border-top: 0;
      border-left: 0;
    }
  }

  input,
  .ant-checkbox-checked::after,
  .ant-checkbox-inner {
    top: 3px;
    width: 22px;
    height: 22px;
  }

  .ant-checkbox-checked {
    &::after {
      border-radius: 5px;
    }
  }
}

.ant-checkbox-wrapper.w-checkbox-large {
  span:last-child {
    color: rgba($text-main, 0.7);

    font-weight: 400;
    line-height: 24px;
    font-size: 20px;
  }

  .ant-checkbox-inner {
    background: #f5fefe;
    border: 0.5px solid #069cc9;
    border-radius: 5px;

    &::after {
      width: 7px;
      height: 11px;
      top: 50%;
      left: 26%;
      border: 3px solid #8492ff;
      border-top: 0;
      border-left: 0;
    }
  }

  input,
  .ant-checkbox-checked::after,
  .ant-checkbox-inner {
    top: 8px;
    width: 32px;
    height: 32px;
  }

  .ant-checkbox-checked {
    &::after {
      border-radius: 5px;
    }
  }
}
