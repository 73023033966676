@import 'assets/css/variables.scss';

.network-container {
  .network-content {
    border-radius: 12px;
    &:not(:last-child) {
      margin-bottom: 64px;
    }

    .network-content-network {
      > .go-back {
        margin: auto;
        margin-bottom: 30px;
        display: none;
        @media (min-width: 768px) {
          display: block;
        }
        .back-title-email {
          width: 300px;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          text-overflow: ellipsis;
          font-weight: 700;
          line-height: 31px;
          text-align: left;
          color: #fff;
          font-size: 16px;
          @media (min-width: 768px) {
            font-size: 20px;
          }
        }

        .back-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 100%;
          text-transform: capitalize;

          @media (min-width: 576px) {
            font-size: 24px;
          }
          @media (min-width: 768px) {
            font-size: 32px;
          }
        }
        p {
          font-weight: 700;
          line-height: 31px;
          text-align: left;
          color: #fff;
          font-size: 16px;
          @media (min-width: 768px) {
            margin-top: 14px;
            font-size: 20px;
          }
          @media (min-width: 1200px) {
            margin-top: unset;
            text-align: right;
            font-size: 32px;
          }
          span {
            font-weight: 500;
            margin-left: 4px;
          }
        }
        @media (min-width: 768px) {
          flex-direction: row;
          margin-bottom: 48px;
        }
        @media (min-width: 1200px) {
          display: flex;
        }
      }
      .network-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        color: $link;
        margin: 24px 0;

        @media (min-width: 991px) {
          font-size: 48px;
          line-height: 62px;
        }
      }
      .ant-row {
        margin-left: 0 !important;
        margin-right: 0 !important;
        @media (min-width: 768px) {
          margin-right: unset;
          margin-left: unset;
        }
      }
      .network-wrap-table {
        &.package-wrap-table {
          margin-top: 24px;
        }
        overflow: auto;
        @include media-up(md) {
          background: $bg-input;
          border-radius: 20px;
          border: 1px solid #3fbdbe;
        }
      }
    }
    .wrapper-table-investment-package-empty {
      position: relative;
      width: 100%;
      height: 300px;
      background: $bg-input;
      border-radius: 20px;
      border: 1px solid #3fbdbe;
      .invest-packge-empty-data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }
  }
}
