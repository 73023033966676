@import 'assets/css/variables.scss';
.button-back-sign-in {
  position: absolute;
  top: -40%;
  left: 24px;
  width: 14px;
  height: 11px;
  cursor: pointer;
  z-index: 2;
}
.verify-email-code {
  max-width: 550px;
  @include media-up(lg) {
    max-height: 550px;
  }
  .verify-title {
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 30px;
    @include media-up(md) {
      font-size: 32px;
      line-height: 48px;
    }
  }
  .verify-resend {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    > div {
      font-weight: 500;
      font-size: 12px;
      @include media-up(md) {
        font-size: 16px;
      }
    }
    > a {
      color: #82c0df;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      @include media-up(sm) {
        font-size: 16px;
      }
    }
    a:hover {
      opacity: 0.7;
    }
    a:active {
      opacity: 1;
    }
  }
  .verify-error {
    color: $warning;
    font-size: 13px;
    text-align: center;
    padding: 20px 10px;
    @include media-up(sm) {
      font-size: 16px;
    }
  }
  .verify-action {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    row-gap: 20px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      a {
        color: #00f4a3;
        font-size: 14px;
        font-weight: 600;
      }
    }
    button {
      max-width: 120px;
    }
  }
}
