@import 'assets/css/variables.scss';

.search-wrapper {
  width: 100%;
  height: 45px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: rgba(0, 32, 41, 0.8);
  outline: none;
  border: none;
  border-radius: 100px;
  img {
    width: 14px;
    cursor: pointer;
    margin-right: 8px;
    object-fit: contain;
  }

  input {
    color: $primary;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-right: 4px;
    width: 100%;
    background: transparent;
    border: unset;
    outline: unset;

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #292929;
      opacity: 0.4;
      @include media-down(md) {
        font-size: 14px;
      }
    }
  }
}
