@import 'assets/css/variables.scss';
.wallet-box-recent-transaction {
  margin-top: 24px;
  overflow: hidden;
  @media (min-width: 768px) {
    margin-top: 32px;
  }

  .recent-transaction-title {
    color: $primary;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
    @include media-down(md) {
      font-size: 16px;
    }
  }
}
