@import 'assets/css/variables.scss';

.input-code-container {
  width: 100%;
  & > div {
    width: 100%;
    flex-flow: row nowrap;
    .input-code-item {
      width: 100%;
      height: 54px;
      @media (min-width: 768px) {
        height: 65px;
      }
      input {
        color: $text-main;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;

        width: 100%;
        height: 100%;
        flex: 1 1 auto;

        display: flex;
        padding: 15px;
        border-radius: 5px;
        text-align: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: $bg-input;
        border: 1px solid rgba(0, 162, 255, 0.5);
        border-radius: 5px;

        &:valid {
          outline: unset;
        }
        &:focus,
        &:hover {
          border: 1px solid #34384c;
        }

        &:not(:first-child) {
          margin-left: 8px;
          @include media-up(sm) {
            margin-left: 20px;
          }
        }

        &.error {
          border: 1px solid $warning;
        }
      }
    }
  }
}
