@import 'assets/css/variables.scss';

.invest-detail-wrapper {
  @include media-down(md) {
    padding-bottom: 110px;
  }
  .container {
    .go-back {
      .back-link {
        color: $link;
        font-weight: 700;
        line-height: 140%;
        span {
          svg {
            path {
              stroke: $link;
            }
          }
        }
      }
    }
    .invest-detail-wrapper-info {
      padding-top: 0;
      @include media-up(md) {
        padding-top: 32px;
      }
      border-radius: 8px;
      border-radius: 12px;
      border: 1px solid #0aadad;
      overflow: hidden;
      .invest-detail-wrapper-info-content {
        background: rgba(238, 251, 255, 0.4);
        border-radius: 12px;
        border-radius: 6px;
        padding: 32px;
        margin: 0 !important;
        @include media-up(md) {
          margin: 0 32px 32px !important;
        }
        .invest-detail-wrapper-img {
          padding: 0 !important;
          max-width: 425px;
          @include media-up(md) {
            height: 425px;
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
        .invest-wrapper-info-detail {
          padding-top: 24px;
          padding-left: 0 !important;
          padding-right: 0 !important;
          @include media-up(md) {
            padding-left: 64px !important;
            padding-top: 0;
          }
          .info-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 140%;
            display: flex;
            align-items: center;
            color: #292929;
          }
          .info-action {
            margin-top: 24px;
            display: flex;
            align-items: center;
            button {
              padding: 0;
              height: 38px;
              color: #00828f;
              border-radius: 4px;
              border: 1px solid #00828f;
              &:first-child {
                margin-right: 24px;
              }
            }
          }
          .info-des {
            margin-top: 24px;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: rgba(41, 41, 41, 0.6);
          }
          .info-des-detail {
            margin-top: 24px;
            table {
              width: 100%;
              min-width: 100%;
              tr {
                td {
                  width: 40%;
                  min-width: 150px;
                  padding-bottom: 24px;
                  text-align: left;
                  p {
                    color: #292929;
                  }
                  &:last-child {
                    p {
                      font-weight: 700;
                      img {
                        width: 14px;
                        margin-left: 8px;
                      }
                    }
                    a {
                      font-size: 14px;
                      color: #007ca2;
                      font-weight: 700;
                      white-space: nowrap;
                      @media (min-width: 768px) {
                        font-size: 16px;
                      }
                      &:hover {
                        img {
                          width: 17px;
                        }
                      }
                      img {
                        transition: 0.2s;
                        width: 16px;
                        margin-left: 8px;
                      }
                    }
                  }
                }
              }
            }
            .info-des-rewards {
              font-weight: 700;
              font-size: 16px;
              line-height: 140%;
              color: #292929;
            }
          }
        }
      }
      .invest-detail-wrapper-attribute {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 32px;
        padding-top: 32px;
        border-top: 2px solid rgba(0, 63, 158, 0.1);
        @include media-up(xl) {
          justify-content: space-between;
        }
        .invest-attribute {
          .attribute-menu-wrapper {
            border-radius: 12px;
            ul {
              background: #caf0ff;
              border-radius: 12px;
              // height: 265px;
              width: 320px !important;
              @include media-up(md) {
                width: 430px !important;
              }
              li {
                &.ant-menu-submenu-open {
                  .ant-menu-submenu-title {
                    span {
                      font-size: 16px;
                      font-weight: 700;
                      @include media-up(md) {
                        font-size: 24px;
                      }
                      .arrow-down {
                        transition: 0.25s;
                        transform: rotate(180deg) translate(0px, 50%);
                      }
                    }
                  }
                }
                &.ant-menu-submenu-inline {
                  font-weight: 700;
                  line-height: 33px;
                  align-items: center;
                  color: #292929;
                  .ant-menu-submenu-title {
                    &:hover {
                      color: #292929;
                      i.ant-menu-submenu-arrow {
                        color: #292929;
                      }
                    }
                    i {
                      display: none;
                    }
                    span {
                      font-size: 16px;
                      font-weight: 700;
                      @include media-up(md) {
                        font-size: 24px;
                      }
                      svg {
                        position: absolute;
                        right: 24px;
                        top: 50%;
                        transform: translate(0%, -50%);
                      }
                    }
                  }
                  .child-item {
                    padding-left: 24px !important;
                    display: flex;
                    font-weight: 400;
                    align-items: center;
                    font-size: 12px;
                    @include media-up(md) {
                      font-size: 16px;
                    }
                    cursor: default;
                    a {
                      margin: 0 4px;
                      font-weight: 700;
                      font-size: 12px;
                      line-height: 140%;
                      color: #007ca2;
                      @include media-up(md) {
                        font-size: 16px;
                      }
                      &:hover {
                        opacity: 0.8;
                      }
                    }
                    img {
                      width: 24px;
                      object-fit: contain;
                      margin-left: 8px;
                      cursor: pointer;
                      &:hover {
                        opacity: 0.8;
                      }
                    }
                    &::after {
                      display: none;
                    }
                    &.ant-menu-item-active,
                    &.ant-menu-item-selected {
                      background: transparent;
                      color: #292929;
                      &:hover {
                        color: #292929;
                      }
                    }
                    .process-check {
                      width: 26px;
                      height: 26px;
                      border-radius: 50%;
                      margin-right: 10px;
                      position: relative;
                      background: #c5c5c5;
                      object-fit: contain;
                      @include media-up(md) {
                        margin-right: 24px;
                      }
                      &.active {
                        background: #00930f;
                        &::before {
                          content: '';
                          position: absolute;
                          width: 6px;
                          height: 2px;
                          left: 40%;
                          top: 55%;
                          border-radius: 8px;
                          background: #fff;
                          transform: translate(-50%, -50%) rotate(45deg);
                        }
                        &::after {
                          content: '';
                          position: absolute;
                          width: 10px;
                          height: 2px;
                          left: 56%;
                          top: 50%;
                          border-radius: 8px;
                          background: #fff;
                          transform: translate(-50%, -50%) rotate(-50deg);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .invest-property,
        .invest-attribute {
          @include media-down(xl) {
            margin-top: 32px;
          }
          .attribute-menu-wrapper {
            border-radius: 12px;
            &.attribute-menu-wrapper-active {
              .ant-menu {
                .ant-menu-submenu-open {
                  ul.ant-menu-sub {
                    background: #caf0ff;
                    height: 193px !important;
                  }
                }
              }
            }
            .property-child-item {
              display: flex;
              align-items: center;
              padding-left: 0 !important;
              cursor: default;
              &::after {
                display: none;
              }
              &.ant-menu-item-active,
              &.ant-menu-item-selected {
                background: transparent;
                color: #292929;
                &:hover {
                  color: #292929;
                }
              }
              &:hover {
                .child-item {
                  color: #292929;
                }
              }
              .child-item {
                &:first-child {
                  width: 200px;
                }
                &:last-child {
                  font-weight: 700 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
