@import 'assets/css/variables.scss';

.wallet-list-desktop {
  .coin-data {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    img {
      width: 100%;
      max-width: 20px;
      margin-right: 10px;
      @include media-up(md) {
        max-width: 24px;
      }
    }

    .coin-data-label {
      display: flex;
      .data-label-text:first-child {
        font-size: 14px;
        line-height: 24px;
        white-space: nowrap;
        @include media-up(md) {
          color: $text-main;
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }
  .actions {
    white-space: nowrap;
    text-align: center;
    max-width: 220px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      color: $primary;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      background: unset;
      border: unset;
      cursor: pointer;

      display: flex;
      align-items: center;

      &:hover:not(&[data-disable='true']) {
        // color: #fff;
      }
      &:active:not(&[data-disable='true']) {
        opacity: 0.7;
      }

      &[data-disable='true'] {
        opacity: 0.7;
        cursor: not-allowed;
      }

      img {
        margin-right: 5px;
      }
    }
  }
  .ant-table-thead tr {
    display: table-row;
    .ant-table-cell {
      white-space: nowrap;
    }
    @include media-up(md) {
      display: table-row;
    }
  }
}
