.invest-bought-history-container {
  @media (min-width: 768px) {
    box-shadow: unset;
    border-radius: 12px;
  }
  .invest-transaction-filter {
    display: none;
    top: 12px;
    right: 0;

    @media (min-width: 991px) {
      display: block;
      position: absolute;
    }

    .input-select-item {
      .box-form-control {
        .ant-select-single {
          .ant-select-selector {
            min-width: 135px;
            width: 100%;
            height: 38px;
            padding-left: 10px !important;
            background: #eefbff;
            border: 1px solid #069cc9;
            border-radius: 8px !important;
            .ant-select-selection-item {
              font-size: 14px;
              color: #000;
            }
          }
          .ant-select-arrow {
            transform: unset;
            right: 12px;
            color: #007ca2;
            .ant-select-suffix {
              svg {
                color: #007ca2;
                width: 11px;
                height: 11px;
                border-left: unset;
                border-top: unset;
                border-right: unset;
              }
            }
          }
        }
      }
    }
  }
  .recent-transaction-title {
    color: #007ca2;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    @media (min-width: 768px) {
      font-size: 32px;
    }
  }
  .recent-transaction-sort {
    .sort-filter-action-left {
      display: flex;
      width: 220px;
      align-items: center;
      justify-content: space-between;
      transform: translateY(7px);
      background: #eefbff;
      border: 1px solid #069cc9;
      border-radius: 8px;
      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #292929;
        margin-bottom: 0;
      }
      img {
        width: 24px;
        cursor: pointer;
        object-fit: contain;
      }
    }
  }
  .ant-table {
    width: 100%;
  }

  .ant-table-wrapper {
    border: 1px solid #08a7b7;
    border-radius: 12px;
    .ant-table {
      background: rgb(238 251 255 / 70%);
      border-radius: 12px;
      padding: 32px;
      tr {
        td {
          padding-bottom: 16px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          text-align: center;
          color: #000000;
          > p {
            color: #000000;
            font-weight: 400;
          }
          @media (min-width: 768px) {
            font-size: 16px;
            line-height: 150%;
          }
        }
      }

      thead tr > th {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: #007ca2;
        background: transparent;

        div {
          color: #007ca2;
          font-weight: 700;
          text-align: center;
          // &:first-child{
          //   text-align: left;
          // }
        }
        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
      tbody tr {
        td {
          &:first-child {
            text-align: left;
            padding: 8px;
          }
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
              color: #000;
              font-weight: 700;
              font-size: 16px;
              line-height: 140%;
              &[data-amount='increase'] {
                color: #00930f;
              }
              &[data-amount='reduced'] {
                color: #ff2f2f;
              }
              &[data-status='Complete'] {
                color: #fff;
                background: #00930f;
                border-radius: 4px;
                max-width: 149px;
                width: 100%;
                padding: 4px 8px;
              }
              &[data-status='Pending'] {
                color: #fff;
                background: #fdc22a;
                border-radius: 4px;
                max-width: 149px;
                width: 100%;
                padding: 4px 8px;
              }
            }
            img {
              width: 32px;
              margin-right: 8px;
              object-fit: contain;
            }
            &.investment-transaction-amount {
              color: #ff2f2f;
              font-weight: 700;
              font-size: 16px;
              line-height: 140%;
            }
          }
        }
      }
    }
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: rgba(#fff, 0.3);
  }
}
