@import 'assets/css/variables.scss';

.wrapper-sign-email {
  z-index: 10;
  h2 {
    font-size: 24px;
    color: #ffffff;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 24px;
    text-align: center;

    @include media-up(lg) {
      font-size: 32px;
    }
  }

  .form-login {
    padding: 0;

    @include media-up(md) {
      padding: 70px 56px;
    }

    .box-wallet-title {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      color: #ffffff;
      font-weight: 700;
      font-size: 20px;
      line-height: 16px;
      margin: 0 0 48px;

      @include media-up(md) {
        display: none;
      }
    }

    .form-title {
      color: #292929;
      font-size: 20px;
      font-weight: 500;
      line-height: 27px;

      @include media-up(md) {
        font-size: 20px;
      }
    }

    .wrapper-label {
      .des-title {
        color: #069cc9;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .form-action {
      width: 100%;
      margin: 16px auto;

      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      @include media-up(sm) {
        margin: 24px auto;
      }
    }

    .form-links {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: $text-main;

      @include media-up(sm) {
        font-size: 16px;
      }

      a {
        color: $link;
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        margin-left: 5px;

        @include media-up(sm) {
          font-size: 16px;
        }

        &:hover {
          opacity: 0.7;
        }

        &:active {
          opacity: 1;
        }
      }
    }
  }
}
