@import 'assets/css/variables.scss';
.app-body-container {
  @include media-up(lg) {
    padding-left: calc($panel-width + 40px);
  }
  .app-body-body {
    display: flex;
    flex-direction: column;
    row-gap: 80px;
  }
  .app-body-content {
    min-height: calc(100vh - $menu-height-desktop - 100px);
    position: relative;
    padding-top: 50px;
    padding-bottom: 0x;
    z-index: 1;

    @include media-down(md) {
      padding-top: 32px;
      padding-bottom: 150px;
    }
  }
}
