.auth-content-left-btn-img-wrapper {
  .auth-content-left-btn-img {
    width: 14px;
    height: 11px;
    cursor: pointer;
    @media (min-width: 768px) {
      display: none;
    }
  }
  padding: 0 24px;
  .box-wallet {
    height: 140px;
    display: flex;
    text-align: right;
    flex-direction: column;
    justify-content: flex-end;
    @media (min-width: 768px) {
      height: unset;
      flex-direction: row;
      justify-content: center;
    }
    .wrap-logo {
      position: relative;
      text-align: center;
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
      .lamp {
        max-width: 100%;
        height: auto;
        object-fit: contain;
      }
      .lamp-light {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        width: 18.104283054%;
        transform: translate(-51%, -304%);
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        animation: 10s light-lamp infinite linear;
      }

      .lamp-glasses {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        width: 39.162011173%;
        transform: translate(-51%, -172%);
        animation: 10s light-lamp-glass infinite linear;
      }
    }
    .wrap-logo-mb {
      position: relative;
      text-align: center;
      @media (min-width: 768px) {
        display: none;
      }
      .logo {
        height: auto;
        max-width: 107px;
        max-height: 107px;
        object-fit: contain;
      }
    }
  }
}

@keyframes light-lamp {
  0% {
    transform: translate(-51%, -304%);
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  1% {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  2% {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  3% {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  4% {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  19% {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  20% {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  21% {
    opacity: 1;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  50% {
    transform: translate(-51%, -294%);
  }
  100% {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    transform: translate(-51%, -304%);
  }
}

@keyframes light-lamp-glass {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0.3;
  }
  2% {
    opacity: 1;
  }
  3% {
    opacity: 0.3;
  }
  4% {
    opacity: 1;
  }
  19% {
    opacity: 1;
  }
  20% {
    opacity: 0.3;
  }
  21% {
    opacity: 1;
  }
  50% {
  }
  100% {
    opacity: 1;
  }
}
