@import 'assets/css/variables.scss';

.table-static-member-item-mobile {
  background: $bg-input;
  border-radius: 20px;
  border: 1px solid #3fbdbe;
  margin-bottom: 16px;
  div {
    padding: 16px 24px;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #3fbdbe;
    &:last-child {
      border: none;
    }
    &:first-child {
      p {
        &:last-child {
          color: #00930f;
          font-weight: 700;
        }
      }
    }
    p {
      font-size: 14px;
      color: #0d0d0d;
      &:first-child {
        font-weight: 700;
        color: #2d3955;
        width: 70%;
      }
    }
  }
}
