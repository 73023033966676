@import 'assets/css/variables.scss';

.input-select-item {
  margin-bottom: 24px;
  @media (min-width: 768px) {
    margin-bottom: 32px;
  }

  .ant-select-selector {
    height: 45px;
    padding: 0 24px !important;
    background: rgba(6, 17, 52, 0.3);
    border: 1px solid rgba(130, 192, 223, 0.3);
    border-radius: 15px !important;
    @media (min-width: 768px) {
      height: 50px;
    }
    .ant-select-selection-placeholder {
      color: $text-main;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
    }
  }
  .ant-select-arrow {
    color: #ffffff;
    right: 24px;
    z-index: 6;
    transform: translate(0px, 2px);
    svg {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #98baff;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: #292929;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 8px;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    font-weight: 500;
    font-size: 14px;
    color: #292929;
  }

  @media (min-width: 991px) {
    p {
      font-size: 20px;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 16px;
    }
  }
}
