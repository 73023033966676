@import './mixins/class';
@import './mixins/mixins';
@import './mixins/breakpoints';

$menu-height-desktop-public: 130px;

$menu-height-desktop: 100px;
$menu-height-mobile: 80px;

$panel-width: 220px;

$text-main: #292929;

$primary: #007ca1;
$secondary: #069cc9;

$bg-input: #eefbff;
$border-input: $secondary;

$success: #11cabe;
$failure: #ed4b9e;
$warning: #ff4d4f;

$in-color: #00930f;
$out-color: $warning;

$link: #007ca2;
$link-hover: #055d77;

$background-card: #1e1f25;

/* =====> Tree color <===== */
$line-ref-color: #002748;
$box-ref-box-shadow: 10px 10px 25px -10px rgba(0, 0, 0, 50%);
$box-ref-border: #d9d9d9;

// level box ref color
$box-ref-color: linear-gradient(to right, #596dfd, #5997fd);
$box-ref-color-lv1: linear-gradient(to right, #596dfd, #5997fd);
$box-ref-color-lv2: linear-gradient(to right, #5d85fc, #a5d3fe);
$box-ref-color-lv3: linear-gradient(to right, #81afff, #9dcbf9);
