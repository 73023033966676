@import 'assets/css/variables.scss';

.auth-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  flex-flow: row nowrap;
  background: #020c2f !important;

  @include media-up(lg) {
    overflow: hidden;
    margin: 0;
    background: unset;
    padding: 100px 0;
  }
  .light-mobile {
    position: absolute;
    width: 517px;
    height: 506px;
    background: #010f41;
    pointer-events: none;
    border-radius: 50%;
    &:nth-child(1) {
      left: 0;
      top: 0;
      transform: translate(-40%, -55%);
    }

    &:nth-child(2) {
      bottom: 0;
      right: 0;
      transform: translate(50%, 55%);
    }
  }
  .light-desktop {
    position: absolute;
    width: 760px;
    height: 760px;
    background: linear-gradient(90deg, #a3a9da 0%, #82c0df 100%);
    opacity: 0.2;
    filter: blur(300px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    pointer-events: none;

    &:nth-child(1) {
      left: 0;
      top: 0;
      transform: translate(0%, 0%);
      animation: hideShowLinear 10s linear infinite;
    }

    &:nth-child(2) {
      right: 0;
      bottom: 0;
      transform: translate(20%, 20%);
      animation: hideShowLinear 10s linear infinite;
    }
  }

  .auth-content-left {
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 24px;
    @include media-up(md) {
      padding-top: 0;
      min-height: calc(100vh - 200px);
    }

    @include media-up(lg) {
      width: 50%;
      display: flex;
    }

    @include media-up(xxl) {
      width: 41%;
    }

    .auth-content-img {
      @include media-up(lg) {
        display: flex;
        margin-top: 100px;
        width: 100%;
        flex-direction: column;
      }
      img {
        height: auto;
        object-fit: contain;

        @include media-up(lg) {
          max-width: 800px;
          max-height: 700px;
        }
      }
    }

    .auth-actions {
      width: 100%;
      height: 40%;
      display: block;
      @include media-up(lg) {
        display: none;
        padding-top: 50px;
      }
      & > a {
        display: block;
        width: 100%;
        max-width: 300px;
        margin: 20px auto;
        text-align: center;
      }
    }
  }
  .auth-content-right {
    display: flex;
    width: 100%;
    max-width: 100%;
    z-index: 0;
    right: 0;
    border-radius: 36px 36px 0px 0px;
    position: relative;
    @include media-up(lg) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      max-width: 800px;
      min-height: calc(100vh - 200px) !important;
      border-radius: 36px 0px 0px 36px;
    }
    @include media-up(xl) {
      width: 60% !important;
      max-width: 100%;
    }
    @include media-up(xxl) {
      width: 59% !important;
      max-width: 100%;
    }
    .auth-content-left-btn {
      @include media-up(md) {
        display: none;
      }
      padding: 0 24px;
      .auth-content-left-btn-img {
        height: 11px;
        width: 14px;
      }
      h3 {
        margin-top: 32px;
        font-weight: 700;
        font-size: 20px;
        line-height: 16px;
        display: flex;
        align-items: center;

        color: #ffffff;
        span {
          color: #f8ff48;
        }
      }
      > p {
        margin-top: 16px;
        font-weight: 400;
        font-size: 20px;
        line-height: 16px;
        color: #ffffff;
      }
    }
    .auth-body-right {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      display: none;
      @include media-up(lg) {
        display: block;
      }
    }
  }
}
