@import 'assets/css/variables.scss';
.exchange-input {
  position: relative;
  margin-bottom: 16px;

  .exchange-input-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .exchange-input-label {
      color: #007ca1;
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      text-transform: capitalize;
    }

    .exchange-input-balance {
      color: #007ca1;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      text-transform: capitalize;
    }
  }
  .exchange-input-input {
    display: flex;
    align-items: center;

    width: 100%;
    height: 48px;
    padding: 2px 12px;
    background: #eefbff;
    border-radius: 8px;
    border: 1px solid #08a7b7;
    @include media-down(sm) {
      height: 46px;
    }

    &.error {
      border-color: red;
    }

    // select
    .ant-select {
      padding-right: 12px;
      border-right: 1px solid #34384c !important;
      &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: unset !important;
        box-shadow: unset !important;
      }
      .ant-select-arrow {
        color: #007ca1;
        top: 50%;
      }
      .ant-select-selector {
        .ant-select-selection-item {
          padding-right: 10px;
        }
        .exchange-dropdown-item {
          display: flex;
          align-items: center;
          .exchange-dropdown-logo {
            width: 24px;
            height: 24px;
            max-width: 24px;
            border-radius: 50%;

            img {
              width: 100%;
              height: auto;
              object-fit: contain;
              vertical-align: baseline;
            }
          }
          .exchange-dropdown-label {
            color: #000;
            font-weight: bold;
            margin-left: 6px;
          }
        }
      }
      .exchange-dropdown {
        .ant-select-item,
        .ant-select-item-option-content {
          padding: 0;
          margin: 0;
          background-color: transparent;
        }

        .exchange-dropdown-item {
          width: 100%;
          padding: 8px 10px;
          display: flex;
          align-items: center;
          &:hover {
            background-color: rgba(0, 0, 0, 0.2);
          }
          .exchange-dropdown-logo {
            img {
              width: 24px;
              height: auto;
              object-fit: contain;
            }
          }
          .exchange-dropdown-label {
            color: #000;
            font-weight: bold;
            margin-left: 6px;
          }
        }
      }
    }
    .ant-select-disabled {
      .ant-select-selector {
        background: transparent;
      }
      .ant-select-arrow {
        display: none;
      }
    }

    // input
    .ant-select,
    .ant-select-selector,
    input,
    input:hover,
    input:focus {
      background: transparent;
      border: unset;
      outline: unset;
    }
    input {
      color: #000;
      font-weight: bold;
      width: 100%;
      padding-left: 14px;
    }

    // right input
    .exchange-input-right {
      a {
        color: #007ca1;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  .exchange-input-error {
    position: absolute;
    top: 100%;
    left: 16px;

    color: red;
    font-size: 13px;
  }
}
