@import 'assets/css/variables.scss';
.wapper-investment-my-nft {
  padding: 0 15px;
  .mynft-header {
    .mynft-input-select {
      max-width: 160px;
      margin-bottom: 32px;
    }
  }
  .my-nft-list {
    .my-nft-list-list-short {
      margin-bottom: 32px;
      @include media-up(md) {
        margin-bottom: 300px;
      }
    }
    .my-nft-list-loading {
      color: $text-main;
      text-align: center;
      width: 100%;
      min-height: 100px;
    }
    .my-nft-list-empty {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .ant-empty-description {
        color: $text-main;
      }
    }
    .my-nft-list-list {
      .my-nft-item-wrapper {
        margin: 0 auto;
        border-radius: 8px;
        background: #eefbff;
        padding: 32px 48px 70px;
        backdrop-filter: blur(4px);
        border: 1px solid #08a7b7;
        .my-nft-item-img {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .my-nft-item-title {
          text-align: center;
          margin-top: 32px;
          font-weight: 700;
          font-size: 20px;
          line-height: 140%;
          @include media-up(md) {
            font-size: 24px;
          }
          a {
            color: #292929;
          }
        }
      }
    }
  }
}
