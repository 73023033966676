$input-height: 50px;

.ant-select.w-select-item {
  &:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: unset;
    background: #f5fefe;
    border: 0.5px solid #069cc9 !important;
    border-radius: 15px;
    box-shadow: unset !important;
    input {
      height: $input-height;
    }
  }
  &:not(.ant-select-customize-input) .ant-select-selector,
  &:not(.ant-select-customize-input) .ant-select-selector input {
    padding-left: 24px;
    padding-right: 24px;
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder,
  .ant-select-selector::after {
    line-height: $input-height;
  }

  .ant-select-selection-search {
    left: 0;
    right: 0;
  }
}
