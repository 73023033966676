@import 'assets/css/variables.scss';

.wallet-list-mobile {
  ::-webkit-scrollbar {
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #8492ff 0%, #ace1ff 100%);
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #5e6588;
    border-radius: 10px;
  }

  .ant-table-cell {
    white-space: nowrap;
    font-size: 13px;
    &:last-child {
      text-align: center;
      @include media-up(sm) {
        text-align: center;
      }
    }

    @media (min-width: 415px) {
      font-size: 14px;
    }

    @include media-up(md) {
      font-size: 16px;
    }

    .actions {
      button {
        background: transparent;
        border: none;
        white-space: nowrap;
      }
      button:first-child {
        margin-right: 5px;
      }
      button:last-child {
        margin-left: 5px;
      }
    }
  }
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-table {
        .ant-table-header {
          .ant-table-cell {
            &:last-child {
              padding: 16px;
            }
          }
        }
        .ant-table-body {
          overflow: hidden !important;
          table {
            border-collapse: none;
            .ant-table-cell-scrollbar {
              display: none;
              padding: 16px;
            }
          }
        }
      }
    }
  }
  .coin-data {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    img {
      width: 100%;
      max-width: 16px;
      margin-right: 10px;

      @media (min-width: 415px) {
        max-width: 20px;
      }

      @include media-up(md) {
        max-width: 24px;
      }
    }

    .coin-data-label {
      div:first-child {
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        white-space: nowrap;

        @media (min-width: 415px) {
          font-size: 14px;
        }

        @include media-up(md) {
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          color: #fff;
        }
      }
    }
  }
  .ant-table-thead tr {
    display: table-row;
    .ant-table-cell {
      padding: 8px 0;
      white-space: nowrap;
      font-size: 13px;

      @media (min-width: 415px) {
        padding: 16px 8px 8px;
        font-size: 16px;
      }

      @include media-up(md) {
        padding: 12px;
      }
    }
    .ant-table-cell-scrollbar {
      box-shadow: none;
      padding: 16px;
    }
    @include media-up(md) {
      display: table-row;
    }
  }
  .wallet-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    border-radius: 10px;

    .coin-data {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      img {
        width: 100%;
        max-width: 30px;
        margin-right: 10px;
      }

      .coin-data-label {
        div:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          white-space: nowrap;

          @include media-up(md) {
            font-size: 20px;
          }
        }
      }
    }
    .wallet-item-right {
      display: flex;
      .balance {
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;

        max-width: 50vw;
        display: flex;
        align-items: center;

        > span {
          color: #a5adcf;
          font-size: 12px;
          padding-left: 4px;
        }
      }
      .actions {
        white-space: nowrap;
        cursor: pointer;
        margin-top: 10px;
        .modal-action {
          button {
            color: #ffffff;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            margin-right: 10px;
            background: unset;
            border: none;
            padding: 2px 6px;
            cursor: pointer;
            &:hover {
              color: #fff;
            }
            &:active {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

.wrapper-wallet-list-mobile {
  background: #eefbff;
  border-radius: 12px;
  padding: 8px;

  .item-currency {
    border-bottom: 1px solid #007ca1;
    padding-bottom: 16px;

    img {
      max-width: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      > p {
        font-size: 14px;
        font-style: normal;
        color: #292929;

        display: flex;
        align-items: center;

        img {
          margin-right: 3px;
        }

        &:first-child {
          font-weight: 700;
        }

        &:last-child {
          font-weight: 400;
        }
      }

      .actions {
        img {
          max-width: 22px;
        }
        button {
          background: transparent;
          border: none;
          white-space: nowrap;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: #292929;
        }
        button:first-child {
          margin-right: 5px;
        }
        button:last-child {
          margin-left: 5px;
        }
      }
    }
  }
}
