@import 'assets/css/variables.scss';

.dashboard-container {
  width: 100%;
  padding: 0 15px;
  .dashboard-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 101%;
    padding-bottom: 16px;
    row-gap: 16px;

    margin-bottom: 32px;
    border-bottom: 1px solid #3fbebf;

    @media (min-width: 991px) {
      border-bottom: transparent;
      padding-bottom: 0;
    }

    @media (min-width: 991px) {
      row-gap: 12px;
    }

    @media (min-width: 1360px) {
      justify-content: space-between;
      width: 100%;
    }

    .dashboard-info-wrap-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 4px;
      max-width: 33%;
      height: 100px;
      @media (min-width: 516px) {
        height: unset;
      }

      @media (min-width: 1356px) {
        max-width: 19%;
      }

      &.active {
        .dashboard-info-item {
          background: linear-gradient(180deg, #013569 0%, #0199bd 100%);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .dashboard-info-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        padding: 8px;
        background: #eefbff;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        min-height: 100px;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 10px;
          left: 0;
          right: 0;
          bottom: 0;
          background: #42c1c4;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
        @media (min-width: 500px) {
          padding: 8px 16px;
        }
        @media (min-width: 576px) {
          min-height: 160px;
          padding: 18px 24px;
        }

        &:not(:last-child) {
          margin-right: 12px;
        }

        .dashboard-info-item-image {
          margin-bottom: 8px;
          width: 22px;
          height: 22px;
          object-fit: contain;
          img {
            max-width: 100%;
            max-height: 100%;
          }
          @media (min-width: 576px) {
            width: 24px;
            height: 24px;
          }
          @media (min-width: 768px) {
            width: 32px;
            height: 32px;
          }
        }
        .dashboard-info-item-info {
          p {
            &:nth-child(1) {
              font-style: normal;
              font-weight: 700;
              color: #007ca2;
              font-size: 16px;
              line-height: 20px;
              @media (min-width: 380px) {
                font-size: 18px;
                line-height: 24px;
              }
              @media (min-width: 768px) {
                font-size: 24px;
                line-height: 33px;
                margin-bottom: 8px;
              }
            }

            &:nth-child(2) {
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              font-size: 9px;
              margin-bottom: 0;
              color: #007ca2;
              white-space: nowrap;
              @media (min-width: 380px) {
                font-size: 10px;
              }
              @media (min-width: 576px) {
                font-size: 12px;
              }
              @media (min-width: 768px) {
                line-height: 21px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .wrap-card-ref {
    padding-bottom: 32px;
  }
}

.modal-level-plan {
  .ant-modal-body {
    background: #020c2f;
    border: 1px solid rgba(130, 192, 223, 0.7);
    border-radius: 12px;
  }

  .modal-content-title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 16px;
  }

  .modal-level-plan-table {
    overflow: auto;

    &::-webkit-scrollbar {
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(90deg, #8492ff 0%, #ace1ff 100%);
      border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #5e6588;
      border-radius: 10px;
    }

    table {
      width: 100%;
      th,
      td {
        font-style: normal;
        font-size: 12px;
        line-height: 100%;
        color: #ffffff;
        border: 1px solid #fff;
        text-align: center;
        padding: 8px 2px;

        @media (min-width: 415px) {
          font-size: 14px;
        }

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 21px;
          padding: 8px;
        }
      }

      th {
        font-weight: 700;
      }

      td {
        font-weight: 500;
      }
    }
  }
}
