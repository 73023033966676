@import 'assets/css/variables.scss';
.box-form-control {
  p {
    color: $primary;
    font-size: 18px;
    line-height: 27px;
  }
  .ant-select {
    width: 100% !important;

    .ant-select-selection-item {
      display: flex;
      align-items: center;

      img {
        width: 24px;
        margin-right: 16px;
      }

      .select-label {
        color: $text-main;
        display: flex;
        align-items: center;
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    .ant-select-selector {
      color: $primary;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      height: 45px;
      // padding: 0 0 0 24px;
      background: $bg-input;
      border: 1px solid $border-input;
      border-radius: 8px;
      @media (min-width: 991px) {
        height: 50px;
      }
      &:hover {
        outline: none;
      }
      .ant-select-selection-search-input {
        height: 100%;
        padding: 10px 14px;
      }
    }

    .ant-select-arrow {
      color: $primary;
      right: 16px;
      z-index: 6;
    }

    .ant-select-selection-placeholder {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  @media (min-width: 991px) {
    p {
      font-size: 18px;
    }

    .ant-select {
      .ant-select-selector {
        font-size: 18px;
        line-height: 27px;
      }
      .ant-select-arrow {
        color: #ffffff;
        right: 24px;
        z-index: 6;
      }
    }
  }
}

.select-with-image-dropdown {
  .ant-select-item {
    display: flex;
    align-items: center;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      img {
        width: 24px;
      }
      span {
        display: flex;
        align-items: center;
        margin-right: 16px;
        font-size: 12px;
        @media (min-width: 991px) {
          font-size: 16px;
        }
      }
    }
  }
}
