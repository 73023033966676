@import 'assets/css/variables.scss';
.account-activity-recent-transaction-table {
  .ant-table {
    background: transparent;
  }
  .ant-table-thead {
    .ant-table-cell {
      color: $text-main;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      text-align: center;
      background: transparent;
      border-bottom: unset;
      &:first-child {
        text-align: left;
        padding-left: 0;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-row:hover .ant-table-cell {
      background: transparent;
    }
    .ant-table-cell {
      color: $text-main;
      font-weight: 600;
      text-align: center;
      padding: 10px;
      border-bottom: unset;
      &:first-child {
        text-align: left;
        padding-left: 0;
      }
    }
  }

  .btn-logout {
    color: #b60000;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
  }
}
