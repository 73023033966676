@import 'assets/css/variables.scss';

.wItemTxhHistory {
  width: 100%;
  padding: 16px;
  background: #eefbff;
  border: 1px solid $secondary;
  border-radius: 12px;

  .history-item-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    & > p {
      color: $text-main;
      margin-bottom: 16px;
      &:first-child {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
      }
      &:last-child {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
}
