@import 'assets/css/variables.scss';

.wallet-item {
  width: 60px;
  height: 60px;
  padding: 6px;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  .active,
  &:hover {
    border-radius: 6px;
    border: 2px solid #e2e2e2;
    background-color: #e2e2e2;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.card-sign-wallet {
  width: 100%;
  padding: 1px;
  max-width: 470px;
  border-radius: 12px;
  background: linear-gradient(179.65deg, #08a7b7 0.79%, #3fbdbe 100%), linear-gradient(0deg, #eefbff, #eefbff);
  z-index: 10;

  .card-content {
    padding: 40px 20px 100px;
    background: #eefbff;
    border-radius: 12px;
    @include media-down(md) {
      padding: 40px 20px 60px;
    }

    h2 {
      color: $text-main;
      font-size: 24px;
      line-height: 33px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 40px;
    }

    .wallet-list {
      max-width: 80%;
      margin: auto;

      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include media-down(md) {
        max-width: 90%;
      }
    }
  }
}
