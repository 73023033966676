@import 'assets/css/variables.scss';

.network-wrap-table {
  .network-table-package {
    width: 100%;
    text-align: center;
    min-width: 500px;

    .network-table-package-th,
    .network-table-package-td {
      padding: 10px 12px;
      font-style: normal;
      line-height: 130%;
      color: #2d3955;
    }

    .network-table-package-th {
      border-bottom: 1px solid #000;
      padding: 16px 0;
      font-weight: 700;
      font-size: 16px;
    }

    .network-table-package-td {
      span {
        font-weight: 500;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        line-height: 130%;
        padding: 4px 16px;
        width: 149px;

        &[data-status='true'] {
          background: #00930f;
          border-radius: 4px;
          color: #fff;
          font-weight: 700;
        }
        &[data-status='Pending'] {
          background: #fdc22a;
          border-radius: 4px;
          color: #fff;
          font-weight: 700;
        }
        &[data-status='False'] {
          color: #e90000;
          color: #f9aa4b;
          background: #ffc8c8;
        }
      }
      &.item-package {
        font-weight: 700;
      }
    }
    .table-tbody-package-empty {
      position: relative;
      height: 300px;
      .network-table-mon-data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .network-wrap-table-tbody-package {
      tr {
        .network-table-package-td {
          p {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
