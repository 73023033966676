@import 'assets/css/variables.scss';

.wrapper-investment-list {
  .react-multiple-carousel__arrow {
    background: linear-gradient(179.65deg, rgba(8, 167, 183, 0.3) 0.79%, rgba(63, 189, 190, 0.3) 100%);
  }

  .react-multi-carousel-list {
    padding-bottom: 32px;

    .react-multi-carousel-dot-list {
      .react-multi-carousel-dot {
        button {
          background: #d9d9d9;
          border: none;
        }

        &.react-multi-carousel-dot--active {
          button {
            background: #069cc9;
          }
        }
      }
    }
  }

  .carousel-item-padding {
    padding: 0 10px;
  }
}
