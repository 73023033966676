@import 'assets/css/variables.scss';
.navigation-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  .navigation-mobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 106px;
    max-width: 100%;
    padding-top: 24px;
    margin: 0 auto;
    overflow: auto;
    --clr: #fff;

    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
    &::-webkit-scrollbar {
      display: none;
    }
    ul {
      width: fit-content;
      height: 100%;
      max-width: 475px;
      margin: 0 auto;
      padding: 0 10px;
      display: flex;
      align-items: center;
      background: #3bbbbd;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background-blend-mode: overlay;
      backdrop-filter: blur(60px);
      li {
        display: flex;
        align-items: center;
        width: 60px;
        position: relative;
        list-style: none;
        z-index: 2;
        a {
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          text-align: center;
          font-weight: 500;

          svg,
          img {
            width: 28px;
            height: 28px;
            position: relative;
            display: block;
            text-align: center;
            transition: 0.5s;
          }
        }
        span {
          color: var(--clr);
          font-size: 8px;
          font-weight: 600;
          letter-spacing: 0.05em;
          margin-top: 5px;
          transition: 0.5s;
        }
      }
      li.active a svg {
        transform: translateY(-12px);
      }
      li.active a span {
        opacity: 0;
        transform: translateY(12px);
      }
      .indicator {
        position: absolute;
        top: -25%;
        width: 80px;
        height: 80px;
        background: #3bbbbd;
        border-radius: 50%;
        transition: 0.5s;
        z-index: 1;
        &::after {
          content: '';
          position: absolute;
          top: 12%;
          left: 10px;
          width: 60px;
          height: 60px;
          background: linear-gradient(90deg, #0075fe 0%, #00c2fe 100%);
          border-radius: 50%;
          transition: 0.5s;
        }
      }
      li:nth-child(1).active ~ .indicator {
        transform: translateX(calc(-10px));
      }
      li:nth-child(2).active ~ .indicator {
        transform: translateX(calc(50px + 0 * 60px));
      }
      li:nth-child(3).active ~ .indicator {
        transform: translateX(calc(50px + 1 * 60px));
      }
      li:nth-child(4).active ~ .indicator {
        transform: translateX(calc(50px + 2 * 60px));
      }
      li:nth-child(5).active ~ .indicator {
        transform: translateX(calc(50px + 3 * 60px));
      }
      li:nth-child(6).active ~ .indicator {
        transform: translateX(calc(50px + 4 * 60px));
      }
      li:nth-child(7).active ~ .indicator {
        transform: translateX(calc(50px + 5 * 60px));
      }
      li:nth-child(7).active ~ .indicator {
        transform: translateX(calc(50px + 6 * 60px));
      }
      li:nth-child(7).active ~ .indicator {
        transform: translateX(calc(50px + 7 * 60px));
      }
      li:nth-child(7).active ~ .indicator {
        transform: translateX(calc(50px + 8 * 60px));
      }
    }
  }
}
// .ant-dropdown {
//   .dropdown-navigation-mobile {
//     display: flex;
//     padding: 3px 0;
//     border-radius: 12px;
//     flex-direction: column;
//     background: rgba(66, 124, 142, 0.9);
//     .dropdown-navigation-mobile-tag {
//       color: #fff;
//       text-align: left;
//       font-weight: 600;
//       padding: 10px 30px;
//     }
//   }
// }
