@import 'assets/css/variables.scss';

.sign-contact-wrapper-2 {
  .go-back {
    .back-link {
      .back-title {
        color: $link;
        font-size: 20px;
      }
      span {
        svg {
          path {
            stroke: $link;
          }
        }
      }
    }
  }
  .container {
    .sign-contact-content-wrapper-2 {
      padding: 8px;
      max-width: 1070px;
      border-radius: 8px;
      background: #eefbff;
      border: 1px solid #0aadad;
      @media (min-width: 768px) {
        padding: 24px;
      }
      .wrapper-img-heading {
        max-width: 100px;
        @media (min-width: 768px) {
          max-width: 180px;
        }
        img {
          width: 100%;
        }
      }
      .wrapper-content-information-2 {
        margin-top: 16px;
        @media (min-width: 768px) {
          margin-top: 32px;
        }
        .ant-row {
        }
        .content-info-item {
          padding-top: 16px;
          p {
            font-size: 12px;
            color: $text-main;
            &.commit-both-part {
              font-weight: 700;
            }
          }
          .ant-col-xs-18 {
            padding: 0 8px;
            @media (min-width: 768px) {
              padding: 0 16px;
            }
          }
        }
        .content-info-item-heading {
          margin-bottom: 16px;
          p {
            font-weight: 700;
            &.content-info-item-heading-content-right {
              max-width: 400px;
              text-align: center;
            }
          }
        }
      }
      .wrapper-btn-contract-signature {
        text-align: center;
        margin: 24px 0;
        width: 100%;
        @media (min-width: 768px) {
          margin-top: 48px;
        }
        button {
          width: fit-content;
          padding: 2px 16px;
        }
      }
    }
  }
}
