@import 'assets/css/variables.scss';
.modal-history-details {
  max-width: 100vw;
  margin: 0 auto;
  padding-bottom: 0;
  .history-detail-heading {
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    font-size: 24px;
    @media (min-width: 768px) {
      font-size: 32px;
    }
  }
  .ant-modal-content {
    @media (min-width: 768px) {
      border-radius: 12px;
      padding: 47px 50px;
    }

    @media (min-width: 991px) {
      padding: 60px;
    }

    .ant-modal-body {
      // padding: 0;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        &.modal-history-details-steps {
          counter-reset: section;

          li {
            position: relative;
            min-height: 52px;
            padding-left: 34px;
            display: flex;
            flex-direction: column;

            &::before {
              counter-increment: section;
              content: counter(section);
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
              color: #fff;
              height: 22px;
              width: 22px;
              background: #0074a6;
              display: flex;
              justify-content: center;
              border-radius: 50%;
              position: absolute;
              z-index: 1;
              left: 0;
              top: 0;
            }

            p {
              margin-bottom: 0;
              font-weight: 700;
              line-height: 27px;
              color: $text-main;
              text-align: left;
              font-size: 14px;
              @media (min-width: 768px) {
                font-size: 16px;
              }
              &:last-child {
                padding-bottom: 14px;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .modal-history-details-note {
        margin-bottom: 16px;
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          margin-bottom: 0;
          text-align: left;
          padding-left: 32px;

          &:first-child {
            color: $text-main;
          }

          &:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 27px;
            text-decoration-line: underline;
            color: #007ca1;
          }
        }
      }

      .modal-history-details-info {
        .modal-history-details-info-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
        }
        .info-item-time {
          p:first-child {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
          p:last-child {
            color: $text-main;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
          }
        }
        .info-item-action {
          p:first-child {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
          p:last-child {
            color: $text-main;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
          }
        }
        .info-item-network {
          p:first-child {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
          p:last-child {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
        }
        .info-item-currency {
          p:first-child {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
          p:last-child {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
        }
        .info-item-fee {
          p:first-child {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
          p:last-child {
            color: $text-main;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
          }
        }
        .info-item-amount {
          p:first-child {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
        }
        .info-item-status {
          p:first-child {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
        }
        .info-item-txh {
          p:first-child {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
          p:last-child {
            color: $text-main;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
          }
        }
      }
    }
  }
}
.ant-modal-wrap {
  top: unset !important;
  overflow: hidden;
  @media (min-width: 768px) {
    top: 0 !important;
    overflow: auto;
    padding-bottom: 0;
  }
}
