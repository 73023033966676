.modal-qrcode-deposit {
  padding: 0;
  top: 140px !important;
  height: unset;
  max-width: 100vw;
  position: relative;
  background: #1a3035;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  margin: 50px auto;
  @media (min-width: 991px) {
    top: 0 !important;
    border-radius: 20px;
    max-width: unset;
  }
  .ant-modal-close {
    top: 25px;
    right: 25px;

    .ant-modal-close-x {
      box-sizing: border-box;
      border-radius: 48px;
      height: 24px;
      width: 24px;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        svg {
          path {
            fill: red;
          }
        }
      }
    }
  }
  .ant-modal-content {
    @media (min-width: 991px) {
      border-radius: 12px;
    }
    .ant-modal-header {
      border-bottom: none;
      background: transparent;
      padding: 40px 52px 0;

      .ant-modal-title {
        text-align: center;
        font-size: 24px;
        @media (min-width: 991px) {
          font-size: 32px;
        }
      }

      @media (min-width: 991px) {
        display: block;
      }
    }

    .ant-modal-body {
      padding: 30px;
      text-align: center;
      @media (min-width: 991px) {
        padding: 52px;
      }
      .modal-deposit-qs-code {
        width: 100%;
        height: 100%;
        max-width: 250px;
        max-height: 250px;
        margin: 0 auto;
        margin-bottom: 16px;
        border: 2px solid white;
        @media (min-width: 991px) {
          max-width: 300px;
          max-height: 300px;
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #777e91;
        margin-bottom: 30px;
        .powered-by {
          color: #005e6b;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
        }
        &.title-modal {
          font-weight: 600;
          font-size: 24px;
          line-height: 130%;
          color: #ffffff;
          margin-bottom: 4px;
        }

        @media (min-width: 991px) {
          font-size: 16px;

          &.title-modal {
            display: none;
          }
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }
  .ant-modal {
    max-width: 100vw;
    top: unset;
    @media (min-width: 576px) {
      max-width: unset;
      position: unset;
    }
  }
}
