@import 'assets/css/variables.scss';

.back-link {
  color: $primary;
  margin-bottom: 26px;
  fill: $primary;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  .back-title {
    .back-icon {
      width: 13px;
      margin-right: 10px;
      @include media-up(md) {
        width: 20px;
        height: 22px;
        margin-right: 14px;
      }
    }
    font-size: 24px;
    font-weight: 600;
    line-height: 100%;
    text-transform: capitalize;

    display: flex;
    align-items: center;

    @include media-up(md) {
      font-size: 32px;
    }
  }
  .back-link-right {
    // .header-go-histoty {
    //   &:hover {
    //     svg {
    //       transform: rotate(-50deg) scale(1.1);
    //     }
    //   }
    // }
    // .header-btn-logout-mb {
    //   &:hover {
    //     svg {
    //       transform: scale(1.1);
    //     }
    //   }
    // }
    // svg {
    //   width: 100%;
    //   height: 100%;
    //   object-fit: contain;
    //   transition: all 0.5s ease-in-out;
    // }
  }
}
