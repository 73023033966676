@import 'assets/css/variables.scss';
.deposit-box-show-address-text {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  > div {
    &:first-child {
      @media (max-width: 768px) {
        justify-content: space-between;
      }
      border: 1px solid #34384c;
      box-sizing: border-box;
      border-radius: 8px;
      margin-right: 8px;
      align-items: center;
      overflow: hidden;
      padding: 0 8px;
      display: flex;
      height: 40px;
      flex: 1;
      @media (min-width: 768px) {
        height: 50px;
        padding: 7px 15px;
        height: 46px;
        margin-right: 16px;
      }
      p {
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: rgba(94, 137, 161, 0.8);
        margin-bottom: 0;
        white-space: break-spaces;
        word-break: break-word;
        @media (min-width: 768px) {
          font-size: 12px;
        }
      }

      @media (min-width: 991px) {
        padding: 14px 29px;
        p {
          font-size: 16px;
        }
      }
    }

    &:last-child {
      position: relative;

      img {
        width: 40px;
        margin-right: 8px;
        @media (min-width: 768px) {
          width: 50px;
        }
        cursor: pointer;
        &:first-child {
          @media (min-width: 576px) {
            margin-right: 16px;
          }
        }
      }

      .tooltip {
        color: $primary;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
      }
    }
  }

  @media (min-width: 991px) {
    margin-bottom: 16px;
  }
}
.ant-modal-root {
  .ant-modal-mask {
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(3px);
    // .ant-modal-wrap {
    //   bottom: 100px;
    //   overflow: hidden;
    //   @media (min-width: 991px) {
    //     top: 0;
    //     overflow: auto;
    //     vertical-align: middle;
    //   }
    // }
    // .ant-modal-centered {
    //   overflow: hidden;
    // }
  }
}
