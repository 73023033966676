@import 'assets/css/variables.scss';

.w-form-signUp {
  width: 100%;
  max-width: 580px;
  .form-signUp {
    .form-title,
    .form-title-country {
      color: $text-main;
      font-size: 16px;
      line-height: 27px;
      font-style: normal;
      font-weight: 500;
      @include media-up(lg) {
        font-size: 20px;
      }
    }
    .control-input-select-country {
      > .ant-select-selector {
        background: rgba(6, 17, 52, 0.3);
      }
    }

    .form-action {
      width: 100%;
      margin: 0 auto;

      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .form-links {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.03em;
      color: $text-main;
      @include media-up(sm) {
        font-size: 16px;
      }
      a {
        color: $link;
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        margin-left: 5px;

        @include media-up(sm) {
          font-size: 16px;
        }

        &:hover {
          opacity: 0.7;
        }
        &:active {
          opacity: 1;
        }
      }
    }
  }
}
