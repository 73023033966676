@import 'assets/css/variables';
$color-1: #007ca2;
$color-2: #01acc4;
$bg-1: rgba(249, 53, 76, 0.2);

.input-coppy-wrapper {
  position: relative;
  .style-input-title {
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
    h3 {
      color: $color-1;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;

      @media (min-width: 768px) {
        font-size: 32px;
      }
    }
  }
  input.ant-input {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #292929;
    cursor: pointer;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 21px;
    }
  }
  input {
    height: 32px;
    cursor: pointer;

    @media (min-width: 991px) {
      height: 42px;
    }
  }
  .ant-input-affix-wrapper {
    border-radius: 5px;
    input {
      background: transparent;
    }
  }

  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-readonly,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:valid {
    border: unset;
    outline: unset;
    box-shadow: unset;
  }

  .wrapper-input {
    position: relative;
    cursor: pointer;
    span {
      color: #ffa143;
    }
  }
  .copy-success {
    position: absolute;
    z-index: 20;
    top: 0;
    color: red;
    background: #5b337e;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    align-items: center;
    display: flex;
    justify-content: center;
    &:active {
      opacity: 0.9;
    }
  }
  .link {
    position: absolute;
    z-index: 22;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: block;
  }
  .icon-fly {
    img {
      width: 13.5px;
    }
  }
  .not-allowed .wrapper-input,
  .not-allowed input,
  .not-allowed img {
    cursor: not-allowed !important;
  }
}
@media only screen and (max-width: 768px) {
  canvas {
    height: auto !important;
  }
  .address-input {
    max-width: 100%;
  }
}
.icon-copy {
  width: 24.75px;
  height: 24.75px;
}
