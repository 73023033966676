@import 'assets/css/variables.scss';

.wCustomModal {
  font-weight: 400;
  font-size: 32px;
  line-height: 27px;
  @include media-down(md) {
    margin: 0 auto;
    max-width: 100vw;
    padding-bottom: 0;
  }

  .ant-modal-content {
    margin: 0 auto;
    background: #eefbff;
    border-radius: 20px;
    box-shadow: none;

    @include media-down(md) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }

    .ant-modal-close {
      top: 16px;
      right: 25px;
      .ant-modal-close-x {
        width: 24px;
        height: 24px;
        line-height: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        .anticon {
          svg {
            font-size: 16px;
            path {
              fill: red;
            }
          }
        }
      }
    }

    .ant-modal-body {
      @include media-down(md) {
        max-height: 83vh;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }

    .ant-modal-header {
      border-bottom: none;
      background: transparent;
      @include media-down(sm) {
        padding: 16px 0;
      }
      .ant-modal-title {
        color: $text-main;
        font-weight: 700;
        font-size: 32px;
        line-height: 27px;
        text-align: center;
        @include media-down(sm) {
          font-size: 22px;
        }
      }
    }

    .ant-modal-footer {
      border: unset;
      border-radius: unset;
      text-align: center;

      button {
        max-width: 120px;
        height: 40px;
        margin: 0 12px;
      }
    }
  }
}
