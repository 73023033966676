@import 'assets/css/variables.scss';

.panel-container {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;

  width: $panel-width;
  min-width: $panel-width;
  max-height: 900px;
  padding-top: 50px;
  border-radius: 20px;
  z-index: 1;

  .panel-logo {
    display: flex;
    justify-content: flex-end;
    padding: 32px 0 64px;
    cursor: pointer;
    img {
      width: 100%;
      height: 60px;
      max-width: 180px;
      margin-right: 35px;
      object-fit: contain;
    }
  }
  .panel-body {
    min-height: 596px;
    background: #eefbff;
    border: 0.25px solid #0aadad;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-top-right-radius: 30px;
    border-bottom-right-radius: 12px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 100%;
      background: linear-gradient(179.65deg, #08a7b7 0.79%, #3fbdbe 100%);
    }
    .panel-top {
      ul.panel-link {
        list-style: none;
        display: flex;
        flex-direction: column;
        border: none;
        .ant-menu-submenu {
          .ant-menu-submenu-title {
            padding-left: 0 !important;
            .panel-link-label-title {
              font-size: 16px;
              align-items: center;
              .panel-link-img-title {
                position: absolute;
                width: 18px;
                height: 18px;
                right: 20px;
                > img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
            .ant-menu-submenu-arrow {
              display: none;
            }
          }
          .ant-menu-inline {
            transition: 0.5s;
            background: transparent;
            .panel-link-label-title-child {
              height: 32px;
              line-height: 30px;
              transition: 0.5s;
              background: transparent;
              .panel-link-label-title-child-link {
                transition: 0.5s;
                margin-left: 8px;
                padding: 0 8px;
                border-radius: 8px;
                font-size: 14px;
                display: flex;
                align-items: center;
                svg {
                  path {
                    stroke: #007ca1;
                    &:last-child {
                      stroke-width: 1;
                    }
                  }
                }
                &:hover {
                  transition: 0.5s;
                  span {
                    font-weight: 700;
                  }
                }
                span {
                  padding-left: 8px;
                  color: #007ca1;
                  font-weight: 400;
                }
                &::after {
                  display: none;
                }
              }
            }
            .ant-menu-item-selected {
              &::after {
                border: none !important;
              }
            }
            .ant-menu-submenu-title {
              padding-left: 8px;
              background: transparent;
            }
          }
        }
        li {
          margin: 8px 0;
          transition: 0.5s;
          z-index: 1;
          .ant-menu-submenu-title {
            margin-top: 0;
            margin-bottom: 0;
            transition: 0.5s;
            overflow: unset;
            a {
              transition: 0.5s;
              color: #007ca1;
              fill: #007ca1;
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              line-height: 26px;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              .panel-link-icon {
                position: relative;
                transition: 0.3s;
                width: 50px;
                height: 40px;
                margin-right: 14px;
                .panel-link-icon-icon {
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);

                  width: 54px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  svg {
                    width: 20px;
                  }
                  img {
                    width: 24px;
                    height: auto;
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }
        li.active,
        li:hover {
          transition: 0.3s;
          &.ant-menu-submenu-open {
            margin: 8px 0;
          }
          span {
            font-weight: 700;
          }
          .ant-menu-submenu-title {
            color: #007ca1;
            fill: #007ca1;
            padding: 0 !important;
            position: relative;
            .panel-link-icon {
              .panel-link-icon-icon {
                background: #24b2bb;
                border-radius: 0px 10px 10px 10px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                svg {
                  width: 22px;
                }
              }
            }
            .panel-link-img-title {
              position: absolute;
              right: 0;
            }
          }
          .ant-menu-inline {
            .ant-menu-item-selected {
              .panel-link-label-title-child-link {
                background: rgba(36, 178, 187, 0.1);
                color: #007ca1;
                fill: #007ca1;
                font-weight: 700;
                transition: 0.5s;
                span {
                  color: #007ca1;
                  font-weight: 700;
                }
              }
            }
            .ant-menu-submenu-title {
              padding-left: 8px;
              background: transparent;
            }
          }
        }
      }
    }
  }
}
