@import 'assets/css/variables.scss';

.wrap-card-ref {
  .box-info-sponserkey {
    background: #eefbff;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    .networks-ref {
      .input-coppy-wrapper {
        .wrapper-input {
          height: 44px;
          @include media-up(md) {
            height: 50px;
          }
          background: $bg-input;
          border: 0.5px solid $secondary;
          border-radius: 8px;
          .ant-input-affix-wrapper {
            padding: 0;
            input {
              color: #292929 !important;
              font-weight: 400;
              padding-left: 24px;
            }
          }
        }
      }
    }

    .password-left {
      padding: 16px 24px;
    }
  }
}
