@import 'assets/css/variables.scss';
.warp-page-withdraw {
  width: 100%;
  padding-bottom: 60px;

  .page-withdraw-content {
    .withdraw-content-form {
      @media (min-width: 768px) {
        // padding: 34px 0;
        background: transparent;
        border-radius: 15px;
      }

      .wallet-box-recent-transaction {
        .recent-transaction-title {
          margin-bottom: 32px;
        }
      }
      .memo-section-content {
        align-items: center;
        .note-warning-meno {
          border-radius: 4px;
          display: flex;
          background: #3d2604;
          .img-warning {
            width: 21px;
            margin: 25px 10px 0;
          }
          p {
            color: white;
            padding: 20px 20px 20px 0;
          }
        }
        img {
          width: 40px;
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
        }
        .tooltip {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 100%);
        }
        .note-required {
          background: #3d2600;
          padding: 0 10px;
          position: relative;
          font-size: 14px;
          color: #d3a107;
          &::before {
            content: '';
            position: absolute;
            background: #3d2600;
            width: 10px;
            height: 10px;
            left: 50%;
            top: -5px;
            transform: rotate(45deg);
            @media (min-width: 525px) {
              left: -4px;
              top: 6px;
            }
          }
        }
      }
      .box-input-select {
        margin-bottom: 24px;

        .input-select-item {
          .box-form-control {
            p {
              font-weight: 600;
              font-size: 16px;
              line-height: 27px;
              margin-bottom: 8px;
              @media (min-width: 768px) {
                font-size: 20px;
                margin-bottom: 16px;
              }
            }
            .ant-input-group {
              .ant-select {
                .ant-select-selector {
                  padding-left: 16px;
                  @media (min-width: 991px) {
                    padding-left: 24px;
                  }

                  .ant-select-selection-item {
                    span {
                      img {
                        width: 24px;
                      }
                    }
                    font-size: 16px;
                  }
                }
                .ant-select-arrow {
                  right: 16px;
                  @media (min-width: 991px) {
                    right: 24px;
                    z-index: 6;
                  }
                }
              }
            }
          }
          &:nth-child(2) {
            .ant-select-selection-item {
              font-weight: 400;
              font-size: 18px;
              line-height: 27px;
            }
          }
        }
      }

      .box-input-text {
        margin-bottom: 16px;
      }

      .withdraw-box-attention {
        margin-bottom: 24px;

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {
            position: relative;
            padding: 0 0 0 24px;

            p {
              font-weight: 400;
              font-size: 13px;
              line-height: 24px;
              color: #53a0cc;

              span {
                font-weight: 600;
                line-height: 150%;
                color: $text-main;
              }
            }

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 8px;
              width: 8px;
              background: $primary;
              border-radius: 50%;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .box-discamler {
        padding: 10px 16px;
        background: #eefbff;
        border: 1px solid #08a7b7;
        border-radius: 10px;
        display: flex;
        .box-discamler-ico {
          width: 26px;
          height: 26px;
          object-fit: contain;
        }
        .box-discamler-content-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: $warning;
          margin-bottom: 4px;
        }
        .box-discamler-content-note {
          color: $text-main;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          @include media-down(md) {
            font-size: 13px;
          }

          span {
            color: $primary;
          }
        }
      }

      .withdraw-actions {
        .error-mess {
          color: #ef466f;
          padding: 32px 0 0;
        }

        & > button {
          margin-top: 20px;
        }
      }
    }
  }
}
@media (min-width: 991px) {
  .warp-page-withdraw {
    .page-withdraw-content {
      .go-back {
        margin-bottom: 32px;
      }
      .withdraw-content-form {
        .memo-section-wrapper {
          margin-bottom: 30px;
          .title-memo {
            font-weight: 700;
            margin-bottom: 8px;
          }
          .token-memo {
            font-weight: 700;
          }
          .memo-section-content {
            align-items: center;
            margin: 0 !important;
            .note-warning-meno {
              border-radius: 4px;
              display: flex;
              background: #3d2604;
              .img-warning {
                width: 21px;
                margin: 25px 10px 0;
              }
              p {
                color: white;
                padding: 20px 20px 20px 0;
              }
            }
            img {
              width: 40px;
              cursor: pointer;
              &:hover {
                opacity: 0.9;
              }
            }
            .tooltip {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 100%);
            }
            .note-required {
              background: #3d2600;
              padding: 0 10px;
              position: relative;
              font-size: 14px;
              color: #d3a107;
              &::before {
                content: '';
                position: absolute;
                background: #3d2600;
                width: 10px;
                height: 10px;
                left: 50%;
                top: -5px;
                transform: rotate(45deg);
                @media (min-width: 525px) {
                  left: -4px;
                  top: 6px;
                }
              }
            }
          }
        }
        .box-input-select {
          margin-bottom: 32px;
        }

        .box-input-text {
          margin-bottom: 16px;
          .input-text-item {
            input {
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              @media (min-width: 768px) {
                font-size: 16px;
              }
              &::placeholder {
                color: rgba(255, 255, 255, 0.4);
              }
            }
          }
        }

        .withdraw-box-attention {
          margin-bottom: 32px;

          ul {
            li {
              padding: 0 0 0 28px;
              margin-bottom: 8px;

              p {
                font-size: 16px;

                span {
                  font-weight: 700;
                  line-height: 24px;
                }
              }

              &::before {
                height: 11px;
                width: 11px;
              }
            }
          }
        }

        .box-discamler {
          padding: 30px 36px;
          background: #eefbff;
          border: 1px solid #08a7b7;
          border-radius: 10px;
          p {
            color: $text-main;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;

            &:first-child {
              color: $warning;
              font-weight: 700;
              font-size: 18px;
              line-height: 150%;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
