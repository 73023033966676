@import 'assets/css/variables.scss';

.forgot-reset-pass {
  max-width: 580px;
  @include media-up(sm) {
    padding: 48px;
  }

  .verify-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 44px;
    text-align: center;
    margin-bottom: 24px;
    text-transform: capitalize;
    display: none;
    @include media-up(md) {
      color: $text-main;
      font-size: 32px;
      margin-bottom: 32px;
      display: block;
    }
  }
  .title-verify-code-forgot {
    margin-bottom: 8px;
  }
  .verify-resend {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    .time-count {
      color: $text-main;
      font-weight: 400;
      line-height: 14px;
      font-size: 14px;
    }
    > a {
      color: #82c0df;
      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      cursor: pointer;
      @include media-up(sm) {
        font-size: 16px;
      }
    }
    a:hover {
      opacity: 0.7;
    }
    a:active {
      opacity: 1;
    }
  }
  .verify-error {
    color: $warning;
    font-size: 13px;
    text-align: center;
    padding: 10px 10px;
    @include media-up(sm) {
      font-size: 16px;
    }
  }
  .verify-action-forgot {
    width: 100%;
    text-align: center;
    margin-top: 26px;

    > button {
      max-width: 130px;
    }
  }
  .verify-input {
    margin-top: 30px;
    p {
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;

      @media (min-width: 576px) {
        font-size: 18px;
      }
    }
  }
}
