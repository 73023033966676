@import 'assets/css/variables.scss';

.investment-card-item {
  border-radius: 8px;
  background: #eefcff;
  border: 1px solid #08a7b7;
  padding: 30px 16px;
  @include media-up(sm) {
    padding: 30px;
  }
  .investment-card-item-dup-logo {
    max-width: 100%;
    text-align: center;
    img {
      width: 100%;
    }
  }
  .investment-item-content {
    h3 {
      margin-top: 12px;
      color: $text-main;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
    }
    .investment-content {
      margin-top: 24px;
      > p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        &:last-child {
          padding-bottom: 0;
        }
        span {
          white-space: nowrap;
          color: #292929;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          &:first-child {
            font-weight: 400;
          }
          &:last-child {
            font-weight: 700;
          }
        }
      }
    }
  }
  .investment-card-item-actions {
    text-align: center;
    margin-top: 24px;
    > button {
      width: 102px;
      height: 46px;
      font-size: 16px;
      padding: 0;
      border-radius: 12px;
      border: transparent;
      background: linear-gradient(135deg, #d2f2fc 0%, #0aadad 100%);
    }
  }
}
