@import 'assets/css/variables.scss';

$panel-width: 295px;

.w-layout-public {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  .linear-block-1 {
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    width: 1200px;
    transform: translate(30%, -60%);
    animation: zoomInOut 42s linear infinite;
    z-index: 1;
  }
  .linear-block-2 {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    width: 1000px;
    transform: translate(-15%, 10%);
    animation: zoomInOut2 40s linear infinite;
    z-index: 1;
    @media (min-width: 768px) {
      width: 2200px;
    }
  }
  .wFooter {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 0);
  }
}
