@import 'assets/css/variables.scss';
.resend-code {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 12px;
    line-height: 24px;
    color: $primary;

    &:nth-child(1) {
      font-weight: 400;
    }

    &:nth-child(2) {
      font-weight: 700;
      cursor: pointer;
    }
  }
}
@media (min-width: 991px) {
  .resend-code {
    p {
      font-size: 16px;
    }
  }
}
