@import 'assets/css/variables.scss';

.wProfileAccount {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  & > .background-cover {
    border-radius: 100%;
    border: 1px solid $primary;
    img {
      width: 45px;
      height: 45px;
      object-fit: contain;
      object-position: center;
      border-radius: 50%;
      @include media-down(md) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .profile-account-info-wrapper {
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    span {
      color: #069cc9;
      font-weight: 700;
      font-size: 20px;
      line-height: 18px;
      white-space: nowrap;
      max-width: 140px;
      text-overflow: ellipsis;
      &.profile-account-wallet {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
      }
    }
  }
}

.wOverlayProfileAccount {
  .ant-dropdown-menu-submenu {
    width: 100%;
    .ant-dropdown-menu-submenu-title {
      border-radius: 12px;
      a {
        img {
          height: 15px;
          right: 10px;
          position: absolute;
        }
      }
      &:hover {
        background: rgba(66, 124, 142, 0.5);
        a {
          color: #fff;
        }
      }
      .ant-dropdown-menu-submenu-expand-icon {
        .ant-dropdown-menu-submenu-arrow-icon {
          svg {
            path {
              display: none;
            }
          }
        }
      }
    }
  }
  a {
    fill: #fff;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;

    width: 100%;
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      g {
        stroke: rgb(208, 208, 208);
      }
      path {
        stroke-width: 2;
      }
    }

    svg {
      max-width: 20px;
      margin-right: 10px;
    }
  }
}
