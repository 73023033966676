@import 'assets/css/variables.scss';

.footer-auth {
  width: 100%;
  min-height: 70px;

  display: none;
  @media screen and (min-width: 991px) {
    display: flex;
    position: fixed;
    top: 0;
    left: -20px;
    z-index: 1;
    right: 0;
  }

  ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    z-index: 5;
    width: 100%;
    margin-right: 50px;
    list-style: none;

    li {
      padding: 40px 30px;
      // @media (min-width: 1290px) {
      //   padding: 40px 20px;
      // }
      a {
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        @include media-up(lg) {
          font-size: 16px;
        }
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
