@import 'assets/css/variables.scss';

.recent-transaction-dashboard {
  background: #eefbff;
  border-radius: 16px;
  width: 100%;
  margin-bottom: 16px;
  padding: 14px;
  @include media-up(md) {
    border: none;
    background: none;
  }

  .item-recent-transaction-view {
    color: #82c0df;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    p {
      margin-bottom: 16px;
      color: #34384c;
      &:first-child {
        font-size: 14px;
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 600;
      }
      .img-curr-type {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      &[data-status='COMPLETED'] {
        span {
          text-transform: capitalize;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #389201;
          border-radius: 10px;
          padding: 0 8px;
          min-width: 80px;
          min-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(68, 187, 94, 0.2);
        }
      }
      span {
        font-size: 14px;
      }
    }

    &:first-child {
      p {
        font-size: 14px;
        line-height: 150%;
        color: #34384c;

        &:first-child {
          font-weight: 500;
          font-size: 14px;
          color: #34384c;
        }
      }
      a {
        font-size: 14px;
      }
    }

    &:last-child {
      p {
        &:last-child {
          color: $text-main;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          display: block;
          &:hover {
            color: $link-hover;
          }
        }
      }
    }
  }
}
