@import 'assets/css/variables.scss';
.w-sign-message {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  z-index: 10;
  @include media-down(md) {
    row-gap: 20px;
  }
  button.btn-sign-message {
    color: $text-main;
    font-size: 24px;
    line-height: 33px;
    font-weight: 700;
    height: unset;
    padding: 10px 20px;
    background: #eefbff;
    border: 1px solid #08a7b7;
    border-radius: 12px;
    @include media-down(md) {
      font-size: 20px;
    }
    &:hover,
    &:active,
    &:focus {
      color: $text-main;
      background: #eefbff;
      border: 1px solid #08a7b7;
      opacity: 0.7;
    }
  }
  button.btn-disconnect-wallet {
    color: $text-main;
    font-size: 24px;
    line-height: 33px;
    font-weight: 700;
    height: unset;
    background: transparent;
    border: unset;
    box-shadow: unset;
    border-radius: 12px;
    @include media-down(md) {
      font-size: 20px;
    }
    &:hover,
    &:active,
    &:focus {
      color: $text-main;
      background: transparent;
      border: unset;
      opacity: 0.7;
    }
  }
}
