.ant-select.header-language {
  .ant-select-selector {
    background: transparent;
    color: #fff;
    border: none;
    width: auto;
    padding: 0;
    height: 42px;
    box-shadow: none;
    .ant-select-selection-item {
      font-weight: 600;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0;

      img {
        margin-left: 7.5px;
        width: 33px;
        object-fit: contain;
      }

      p {
        font-size: 16px;
        font-weight: 600;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #069cc9;
      }
    }
  }
  .ant-select-arrow {
    display: none;
  }
}

// dropdown
.header-language-dropdown {
  .ant-select-item-option-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      margin-right: 10px;
    }
    .header-language-img-default {
      display: none;
    }
  }
}
