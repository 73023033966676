@import 'assets/css/variables.scss';

.wrapper-sign-option {
  height: 100%;
  min-width: 100vw;
  min-height: calc(100vh - $menu-height-desktop - 1px);

  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url(../../../../assets/images/background01.png);
  background-size: cover;
  background-position: center 20%;

  position: relative;

  @include media-down(md) {
    background-image: url(../../../../assets/images/bg-mobile.png);
  }
  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: rgba(#fff, 0.3);
  // }
}
