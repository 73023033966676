@import 'assets/css/variables.scss';

.wallet-transaction-history {
  .ant-table-header {
    .recent-transaction-table-time-header {
      white-space: nowrap;
      text-align: center;
      padding: 0 8px;
    }
  }

  .ant-table-tbody {
    .recent-transaction-table-time {
      color: $text-main;
      white-space: nowrap;
      text-align: center;
    }

    .recent-transaction-table-action {
      color: $text-main;
    }

    .recent-transaction-table-network {
      color: $text-main;
    }

    .recent-transaction-table-currency {
      color: #fcfcfd;
    }
    .recent-transaction-table-amount {
      font-size: 16px;
      font-weight: bold;
    }
    .recent-transaction-table-actions {
      color: $link;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      display: block;
      &:hover {
        color: $link-hover;
      }
    }
    a.recent-transaction-table-txh {
      color: $link;
      font-size: 16px;
      text-align: center;
      display: block;
      &:hover {
        color: $link-hover;
      }
    }
    p.recent-transaction-table-txh {
      color: $text-main;
      font-size: 16px;
      text-align: center;
    }
    .recent-transaction-table-status {
      display: flex;
      justify-content: center;
    }
  }
}
