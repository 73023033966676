@import 'assets/css/variables.scss';

.investment-wrapper {
  max-width: 1070px;
  .investment-link-information {
    margin-top: 24px;
    @media (min-width: 768px) {
      margin-top: 32px;
    }

    a {
      position: relative;
      cursor: pointer;
      font-size: 16px;
      line-height: 140%;
      color: #007ca2;
      font-weight: 700;

      @media (min-width: 768px) {
        font-size: 20px;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 2px;
        background-color: #007ca2;
      }
    }
  }
  .invest-transaction {
    margin-top: 0px;

    @media (min-width: 768px) {
      margin-top: 50px;
    }

    .ant-card-contain-tabs {
      background: transparent;
      border: none;
      padding: 0;
      .ant-card-head {
        position: relative;
        padding: 0;
        border-bottom: 0;
        .ant-tabs-top {
          .ant-tabs-nav {
            &::before {
              border: none;
              display: none;
            }
          }
        }
        .ant-tabs-nav-list {
          height: 38px;
          border-bottom: 0;
          border: 1px solid #26b3bb;
          border-radius: 8px;
          text-align: center;
          margin: 0 auto;
          max-width: 334px;
          width: 100%;
          @media (min-width: 768px) {
            height: 58px;
            border: none;
            max-width: 840px;
            margin: unset;
          }

          &::before {
            border-bottom: 0;
          }

          .ant-tabs-tab {
            position: relative;
            color: rgba(0, 124, 162, 0.6);
            padding: 4px 12px;
            border-radius: 6px;
            width: 50%;

            @media (max-width: 992px) {
              margin-left: 0;
            }

            &:first-child {
              @media (min-width: 768px) {
                &::after {
                  content: '';
                  position: absolute;
                  width: 4px;
                  height: 48px;
                  right: -20px;
                  top: 5px;
                  border-radius: 12px;
                  background: rgba(0, 63, 158, 0.1);
                }
              }
            }

            &.ant-tabs-tab-active {
              border: 1px solid #26b3bb;
              background: #d8ecff;
              width: 50%;
              @media (min-width: 768px) {
                border: none;
              }

              div.ant-tabs-tab-btn {
                color: #007ca2;
              }
            }

            div.ant-tabs-tab-btn {
              font-size: 12px;
              line-height: 140%;
              margin: 0 auto;
              @media (min-width: 576px) {
                font-size: 16px;
              }
              @media (min-width: 768px) {
                font-size: 24px;
              }
            }
          }
          .ant-tabs-ink-bar {
            background: none;
          }
        }
      }
      .ant-card-body {
        padding: 0;
        margin-top: 32px;
        @include media-down(sm) {
          margin-top: 12px;
        }
      }
    }
  }
}
