@import 'assets/css/variables.scss';

.card-option {
  width: 100%;
  max-width: 500px;

  display: flex;
  flex-direction: column;
  row-gap: 40px;
  z-index: 10;
  @media (max-width: 575px) {
    row-gap: 20px;
  }

  .btn-sign-in {
    @include media-down(sm) {
      width: 100%;
      max-width: 280px;
      margin: 0 auto;
    }
  }

  .signup-now {
    color: #292929;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    a {
      color: #069cc9;
      font-weight: bold;
      white-space: nowrap;
    }
  }
}
