@import 'assets/css/variables.scss';

.wrapper-form-forgot {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @media screen and (min-height: 400px) {
    align-items: center;
  }

  .forgot-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: $text-main;
    line-height: 27px;
    text-align: center;
    margin-bottom: 48px;

    @include media-up(lg) {
      font-size: 32px;
    }
  }
  .forgot-label {
    color: $text-main;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
    @include media-up(md) {
      font-size: 20px;
    }
  }
}
