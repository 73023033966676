@import 'assets/css/variables.scss';

$panel-width: 295px;

.wrapper-big {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  .linear-block-1 {
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    z-index: 1;
    width: 1200px;
    transform: translate(30%, -60%);
    animation: zoomInOut 42s linear infinite;
  }
  .linear-block-2 {
    position: absolute;
    pointer-events: none;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 1000px;
    transform: translate(-15%, 10%);
    animation: zoomInOut2 40s linear infinite;
  }
  .private-layout-container {
    width: 100%;
    position: relative;
    background: #fbfbfb;
    .collapse-icon {
      display: flex;
      align-items: center;
      transform: scale(-1);
      z-index: 11;
      input#menu {
        display: none;
      }

      .icon {
        width: 34px;
        height: auto;
        padding: 0;
        display: block;
        cursor: pointer;
      }

      .icon .menu,
      .icon .menu::before,
      .icon .menu::after {
        background: #fff;
        content: '';
        display: block;
        height: 3px;
        position: absolute;
        transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
      }
      .icon .menu::before {
        width: 28px;
      }
      .icon .menu {
        width: 24px;
      }
      .icon .menu::after {
        width: 34px;
      }

      .icon:hover .menu,
      .icon:hover .menu::before,
      .icon:hover .menu::after {
        background: #fff;
      }

      .icon .menu::before {
        top: -8px;
      }

      .icon .menu::after {
        top: 8px;
      }

      #menu:checked + .icon .menu {
        background: transparent;
      }

      #menu:checked + .icon .menu::before {
        width: 28px;
        transform: rotate(45deg);
      }

      #menu:checked + .icon .menu::after {
        width: 28px;
        transform: rotate(-45deg);
      }

      #menu:checked + .icon .menu::before,
      #menu:checked + .icon .menu::after {
        top: 0;
        transition: top ease 0.3s, transform ease 0.3s 0.3s;
      }
    }

    .private-content-body {
      width: 100%;
      max-width: 1290px;
      margin: 0 auto;
      position: relative;
    }
  }
}
