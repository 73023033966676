@import 'assets/css/variables.scss';
.modal-connect {
  .ant-modal-content {
    padding: 48px;
  }
  .wallet-list {
    max-width: 80%;
    margin: auto;

    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media-down(md) {
      max-width: 90%;
    }
  }
}
