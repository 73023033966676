@import 'assets/css/variables.scss';

.wrapper-input {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  background: #f5fefe;
  border: 0.5px solid #069cc9;
  border-radius: 6px;
  outline: unset;

  @include media-up(md) {
    border-radius: 15px;
  }

  &[data-size='small'] {
    padding: 8px 8px 8px 16px;
    height: 18px;

    @include media-up(sm) {
      height: 26px;
    }
  }

  &[data-size='standard'] {
    padding: 8px 24px 8px 24px;
    height: 45px;

    @include media-up(sm) {
      height: 50px;
    }
  }

  &[data-size='larger'] {
    padding: 8px 8px 8px 24px;
    height: 56px;

    @include media-up(sm) {
      height: 66px;
    }
  }

  .phone-suffix {
    color: #292929;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    white-space: nowrap;
    @include media-up(md) {
      font-size: 16px;
    }

    margin-right: 16px;
    position: relative;
    &::before {
      content: '';

      position: absolute;
      right: -9px;
      top: 50%;
      translate: 0 -50%;

      display: inline-block;
      height: 80%;
      width: 2px;
      background-color: black;
    }
  }

  input {
    color: rgba(#292929, 0.7);
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;

    width: 100%;
    height: 100%;
    background: transparent;
    border: unset;
    outline: unset;

    @include media-up(md) {
      font-size: 16px;
    }

    &::placeholder {
      font-size: 12px;
      @include media-up(md) {
        font-size: 16px;
      }
    }

    &:hover,
    &:valid {
      outline: unset;
    }
  }

  svg {
    width: 15px;
    margin-left: 10px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }
}
